import React, {Fragment} from "react"
import Ticket from "../ticketWrapper/ticket";

const CreateTickets = (props) => {
    return (
        <Fragment>
            {!props.createdTickets.length > 0 ? null :
                <div id="waiting-tickets"
                     className="waiting-tickets created-tickets-wrapper ">
                    <div className='ticket-text'>Waiting Tickets 
                        {/* <span> Total - {props.createdTickets.length}</span> */}
                    </div>
                    {props.createdTickets.map((item, index) => {
                        return <Ticket deleteIcon={true} key={index} id={item.Id} name={item.Name}
                                       balls={item.Balls} ticketNumber={item.TicketNumber}
                                       isState={item.State} totalBet={item.BetAmount}
                                       totalWin={item.WinAmount} systemType={item.SystemType}/>
                    })
                    }
                     {/*{
                                this.state.isLoading ? <Loading width="40" height="40"></Loading> : null
                            }*/}
                </div>}
        </Fragment>

    )
}
export default CreateTickets;