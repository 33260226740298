import React, {Component} from 'react';
import Collapsible from 'react-collapsible';
import GamesList from '../gamesList/index';
import Competition from '../gamesList/Competition';
import Title from '../Title';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {addRemoveFavorites} from '../../../../actions/favoriteActions';
import {getFavorite} from '../../../../actions/getFavorite';
import {getCountRounds, getRounds, getStatusRound} from '../../../../actions/roundActions';
import {getBetAmountRange, getUpDateTickets} from "../../../../actions/ticketActions";
import {getDroppedNumber} from "../../../../actions/numberActions";

class Favorites extends Component {

    state = {
        favoritesIsOpened: false
    }
    getFavorites = () => {
        if (!this.state.favoritesIsOpened) {
            this.props.getFavorite()
        }
        this.setState((prevState) => {
            return {favoritesIsOpened: !prevState.favoritesIsOpened}
        })
    }

    getNewData = (item) => {
        this.props.getRounds(item);
        this.props.getUpDateTickets(this.props.token, this.props.partnerId, this.props.gameId);
        this.props.getStatusRound(item);
        this.props.getDroppedNumber(item);
    };


    render() {
        return (
            <div className="favorite-view left-nav-view">
                <ul>
                    <li>
                        <div className="float-width">
                            <Collapsible trigger={
                                <Title
                                    type="favorites"
                                    name="Favorites"
                                    onClick={this.getFavorites}
                                />
                            }>
                                {/* collabsible section start */}
                                <GamesList favorites={this.props.favorites}>
                                    {/* competition-title should be on loop */}
                                    {this.props.favorites ?
                                            this.props.favorites.map((item, index) => {
                                                for (let value of this.props.competitions) {
                                                    if (value.Id === item.Id) {
                                                        item = value
                                                    }
                                                }
                                                return <Competition
                                                    onClick={() => this.getNewData(item)}
                                                    key={index}
                                                    name={item.Name}
                                                    selectedCount={item.MaxSelectedNumbersCount}
                                                    numbersCount={item.NumbersCount}
                                                    type="favorites"
                                                    removeFavorite={this.props.addRemoveFavorites}
                                                    itemId={item.Id}
                                                    className="fav-competition"
                                                />
                                            })
                                            : null}
                                </GamesList>
                                {/* collabsible section end */}
                            </Collapsible>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

export default connect(
    state => ({
        favorites: state.favorites.favoritesList,
        competitions: state.competitions.competitionsAll,
        counter: state.rounds.counter,
        competitionId: state.rounds.competitionId,
        token: state.user.token,
        partnerId: state.user.partnerId,
        gameId: state.user.gameId
    }),
    dispatch => bindActionCreators(
        {
            addRemoveFavorites, getRounds,
            getFavorite, getCountRounds,
            getStatusRound, getUpDateTickets,
            getDroppedNumber, getBetAmountRange
        }, dispatch)
)(Favorites);

