import {
    GET_SYSTEM_TYPES, SET_SELECTED_SYSTEM_TYPE,
    HANDLE_BALL_CLICK, GET_PROBABILITY,
    GET_TAX_AND_STAKE, GET_RANDOM_SELECTED_MAX_VALUE,
    GET_CREATED_TICKETS, SET_BET, GAME_END,
    ADD_CREATED_TICKET, DELETE_TICKET, UPDATE_TICKETS,
    CLEAR_BALLS, DELETE_CREATING_TICKET, SET_TOTAL_TICKET,
    GET_TICKETS_HISTORY, SET_TICKET_COUNT,
    GET_FINISHED_TICKETS, ADD_FINISHED_TICKETS, DELETE_TICKETS, GET_WINNER_TICKETS,
    GET_BET_AMOUNT_RANGE, CLEAR_UPDATE_TICKETS, ACTIVE_BUTTON, SET_ACTIVE_BUTTON, CHANGE_COMPETITIONID
} from "../constants";

const ACTION_HANDLERS = {
    [CLEAR_BALLS]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                selectedBalls: [],
                systemTypes: [],
                selectedSystemId: null,
                probability: null,
                bet: state.currentCreatingTicket.defaultBet
            },
        }
    },
    [DELETE_CREATING_TICKET]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                selectedBalls: [],
                systemTypes: [],
                selectedSystemId: null,
                probability: null,
                competitionId: null,
                regionId: null,
                bet: state.currentCreatingTicket.defaultBet
            }
        };
    },
    [HANDLE_BALL_CLICK]: (state, action) => {
        let competitionChanged = state.currentCreatingTicket.competitionId !== action.payload.competitionId && action.payload.competitionId !== undefined;
        let selectedBalls = state.currentCreatingTicket.selectedBalls;
        if (!competitionChanged) {
            const index = selectedBalls.indexOf(action.payload.id);
            if (index === -1) {
                if (selectedBalls.length < 15) {
                    selectedBalls.push(action.payload.id);
                }
            } else {
                selectedBalls.splice(index, 1);
            }
        }
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                selectedBalls: competitionChanged ? [action.payload.id] : [...selectedBalls],
                systemTypes: selectedBalls.length === 0 ? [] : state.currentCreatingTicket.systemTypes,
                competitionId: competitionChanged ? action.payload.competitionId : state.currentCreatingTicket.competitionId,
                regionId: competitionChanged ? action.payload.regionId : state.currentCreatingTicket.regionId,
                bet: competitionChanged ? state.currentCreatingTicket.defaultBet : state.currentCreatingTicket.bet
            },
        }
    },

    [GET_SYSTEM_TYPES]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                systemTypes: action.payload,
                selectedSystemId: action.payload.length !== 0 ? action.payload[0].Id : null,
                probability: action.payload.length !== 0 ? state.currentCreatingTicket.probability : 0
            },
        }
    },
    [SET_SELECTED_SYSTEM_TYPE]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                selectedSystemId: action.payload
            }

        }
    },
    [GET_PROBABILITY]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                probability: action.payload * 100,
            }
        }
    },
    [GET_TAX_AND_STAKE]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                tax: action.payload.Tax,
                stake: action.payload.Stake,
                winAmount: action.payload.WinAmount
            }
        }
    },
    [GET_RANDOM_SELECTED_MAX_VALUE]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                selectedBalls: action.payload.randomBallsArr,
                competitionId: action.payload.competitionId,
                regionId: action.payload.regionId,
                bet: state.currentCreatingTicket.defaultBet
            }
        }
    },
    [SET_BET]: (state, action) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                bet: action.payload
            }
        }
    },
    [GET_CREATED_TICKETS]: (state, action) => {
        return {
            ...state,
            createdTickets: {
                tickets: action.payload.tickets,
            }
        }

    },
    [UPDATE_TICKETS]: (state, action) => {
        //state.updateTickets = []
        return {
            ...state,
            updateTickets: {
                tickets: state.updateTickets.tickets ? state.updateTickets.tickets.concat([action.payload.tickets]) : [action.payload.tickets]
            },
            createdTickets: {
                tickets: state.createdTickets.tickets.map((elem) => {
                    if (elem.Id === action.payload.tickets.Id) {
                        elem.State = 1
                    }
                    return elem
                })
            }
        }
    },
    [CLEAR_UPDATE_TICKETS]: (state) => {
        return {
            ...state,
            updateTickets: {
                tickets: []
            }
        }
    },
    [ADD_CREATED_TICKET]: (state, action) => {
        return {
            ...state,
            createdTickets: {
                tickets: state.createdTickets.tickets.length === 0 ? [action.payload] : [action.payload].concat(state.createdTickets.tickets),
                totalCount: state.createdTickets.totalCount === 0 ? 1 : ++state.createdTickets.totalCount
            }
        }
    },
    [DELETE_TICKET]: (state, action) => {
        let index;
        for (let i in state.createdTickets.tickets) {
            if (state.createdTickets.tickets[i].Id === action.payload) {
                index = i;
                break;
            }
        }
        let tickets = state.createdTickets.tickets;
        tickets.splice(index, 1)
        return {
            ...state,
            createdTickets: {tickets: [...tickets], totalCount: --state.createdTickets.totalCount}
        }
    },
    [GET_TICKETS_HISTORY]: (state, action) => {
        if (state.ticketsHistory.counter === action.payload.counter) {
            state.ticketsHistory.allTickets = [];
            return {
                ...state,
                ticketsHistory: {
                    allTickets: action.payload.tickets,
                    totalCount: action.payload.totalCount,
                    totalBet: action.payload.totalBet,
                    totalWin: action.payload.totalWin,
                    counter: state.ticketsHistory.counter
                }
            }
        } else {
            return {
                ...state,
                ticketsHistory: {
                    allTickets: [...state.ticketsHistory.allTickets, ...action.payload.tickets],
                    totalCount: action.payload.totalCount,
                    totalBet: action.payload.totalBet,
                    totalWin: action.payload.totalWin,
                    counter: state.ticketsHistory.counter
                }
            }
        }
    },
    [GET_WINNER_TICKETS]: (state, action) => {
        return {
            ...state,
            winnerTickets: {
                tickets: action.payload
            }
        }
    },
    [GET_FINISHED_TICKETS]: (state, action) => {
        if (state.ticketFinishedHistory.counter === action.payload.counter) {
            state.ticketFinishedHistory.tickets = [];
            return {
                ...state,
                ticketFinishedHistory: {
                    tickets: action.payload.tickets,
                    totalCount: action.payload.totalCount,
                    totalBet: action.payload.totalBet,
                    totalWin: action.payload.totalWin,
                    counter: state.ticketFinishedHistory.counter
                }
            }
        } else {
            return {
                ...state,
                ticketFinishedHistory: {
                    tickets: [...state.ticketFinishedHistory.tickets, ...action.payload.tickets],
                    totalCount: action.payload.totalCount,
                    totalBet: action.payload.totalBet,
                    totalWin: action.payload.totalWin,
                    counter: state.ticketFinishedHistory.counter
                }
            }
        }
    },
    [SET_TICKET_COUNT]: (state, action) => {
        return {
            ...state,
            ticketsHistory: {
                counter: action.payload.counter
            }
        }
    },
    [GAME_END]: (state, action) => {
        return {
            ...state,
            gameEnd: action.payload
        }
    },
    [ADD_FINISHED_TICKETS]: (state) => {
        return {
            ...state,
            ticketFinishedHistory: {
                tickets: state.ticketFinishedHistory.tickets ? [...state.updateTickets.tickets, ...state.ticketFinishedHistory.tickets] : state.updateTickets.tickets
            },
            createdTickets: {
                tickets: state.createdTickets.tickets.filter((elem) => elem.State !== 1),
            },
            updateTickets: {
                tickets: []
            },
        }
    },
    [SET_TOTAL_TICKET]: (state, action) => {
        return {
            ...state,
            totalTicket: action.payload
        }
    },
    [DELETE_TICKETS]: (state, action) => {
        return {
            ...state,
            createdTickets: {
                tickets: state.createdTickets.tickets.filter((elem) => elem.RoundId !== action.payload.RoundId)
            },
            ticketFinishedHistory: {
                tickets: [...action.payload.tickets, ...state.ticketFinishedHistory.tickets]
            }
        }
    },
    [GET_BET_AMOUNT_RANGE]: (state, action) => {
        return {
            ...state,
            getBetAmountRange: action.payload
        }
    },
    [ACTIVE_BUTTON]: (state) => {
        return {
            ...state,
            activeButton: true
        }
    },
    [SET_ACTIVE_BUTTON]: (state) => {
        return {
            ...state,
            activeButton: null
        }
    },
    [CHANGE_COMPETITIONID]: (state) => {
        return {
            ...state,
            currentCreatingTicket: {
                ...state.currentCreatingTicket,
                competitionId: null,
                selectedBalls: []
            }
        }
    }
};

const initialState = {
    currentCreatingTicket: {
        selectedBalls: [],
        systemTypes: [],
        selectedSystemId: null,
        probability: null,
        competitionId: null,
        regionId: null,
        tax: 0,
        stake: 0,
        winAmount: 0,
        defaultBet: 1,
        bet: 1
    },
    getBetAmountRange: null,
    createdTickets: {
        tickets: [],
    },
    winnerTickets: {
        tickets: [],
    },
    updateTickets: {
        tickets: [],
    },
    ticketsHistory: {
        newTickets: [],
        allTickets: [],
        totalCount: null,
        totalBet: null,
        totalWin: null,
        counter: 1
    },
    ticketFinishedHistory: {
        tickets: [],
        totalCount: null,
        totalBet: null,
        totalWin: null,
        // counter: 1
    },
    gameEnd: false,
    totalTicket: 0,
    activeButton: null
}

const ticketReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default ticketReducer;