import {GET_FAVORITES, GET_COMPETITIONS, GET_COMPETITION} from "../constants";
import {openMessagePopup} from './UI_actions';
import {call} from "./call";



export const addRemoveFavorites = (competitionId, event) => (dispatch, getState) => {
    let competitions = getState().competitions.competitionsAll;
    let favorites = getState().favorites.favoritesList;
    let method = "Add";
    let currentCompetition = getState().rounds.competition;
    if (event !== undefined) {
        event.stopPropagation();
        method = "Delete";
    } else if (currentCompetition.IsFavorite) method = "Delete";
    call({Controller: "Favorite", Method: method, RequestData: {CompetitionId: competitionId}})(getState)
        .then((res) => {
            if (res.status === 200) {
                if (method === "Delete") {
                    // search favorite in favorites list and set it's region id in regionId variable
                    favorites = favorites.filter((elem) => competitionId !== elem.Id)
                } else if (method === "Add") {
                    favorites.push(currentCompetition)
                }
                dispatch({
                    type: GET_FAVORITES,
                    payload: favorites,
                });
                competitions.forEach((element) => {
                    if (element.Id === competitionId) {
                        element.IsFavorite = !element.IsFavorite;
                        let competition = !element.IsFavorite
                        dispatch({
                            type: GET_COMPETITION,
                            competition: {...element, competition }
                        });
                    }
                });
                dispatch({
                    type: GET_COMPETITIONS,
                    payload: competitions,
                });
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
};

export const getFavoriteCompetitions = () => (dispatch, getState) => {
    let competitions = getState().competitions.competitionsList;
    call({Controller: "Favorite", Method: "GetAll"})(getState)
        .then(res => {
            if (res.status === 200) {
                let favoritesArray = [];
                // favorites array element model {RegionId:[competitions array]}
                if (res.data.Object.length !== 0) {
                    favoritesArray.push({[res.data.Object[0].RegionId]: [{...res.data.Object[0], IsFavorite: true}]})
                }
                for (let received of res.data.Object) {
                    if (received === res.data.Object[0]) continue;
                    let regionIsAdded = false
                    for (let added of favoritesArray) {
                        if (added.hasOwnProperty(received.RegionId)) {
                            added[received.RegionId].push({...received, IsFavorite: true});
                            regionIsAdded = true;
                        }
                    }
                    if (!regionIsAdded) favoritesArray.push({[received.RegionId]: [{...received, IsFavorite: true}]})
                }

                //setting competitions
                for (let value of favoritesArray) {
                    if (competitions === null || !competitions.hasOwnProperty(Object.keys(value)[0])) {
                        dispatch({
                            type: GET_COMPETITIONS,
                            competitions: value[Object.keys(value)[0]],
                            regionId: Object.keys(value)[0]
                        });
                    }
                }
                // creating references for favorites *from favorites to competitions
                let favorites = [];
                for (let value of favoritesArray) {
                    for (let competitions of value[Object.keys(value)[0]]) {
                        favorites.push({[competitions.RegionId]: competitions.Id})
                    }
                }
                dispatch({
                    type: GET_FAVORITES,
                    payload: favorites,
                });
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
} 
