import { CHECK_MOBILE } from "../constants";

const ACTION_HANDLERS = {
    [CHECK_MOBILE]: (state, action) => {
        return {
            ...state,
            mobileSize : action.payload < 813 ? true : false
        }
    },
}

const initialState = {
    mobileSize: window.innerWidth < 813 ? true : false
}

const mobileReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default mobileReducer;