import React, { Component } from "react";
import Button from "../components/Button";
class Popup extends Component {
   render() {
      return (
         <div className="popups-container">
            <div className="alertContainer">
               <div id="alertMessage" className="alertDiv">
                  <div className="header">{this.props.headerText}</div>
                  <div className={`informationDiv`}>
                     <p className="">
                        <span>{this.props.message}</span>
                     </p>
                  </div>

                  <div className="buttonsContainer">
                     <Button
                        onClick={() => this.props.closePopup()}
                        name="ok"
                     />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default Popup;
