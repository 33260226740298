import {
    OPEN_POPUP,
    CLOSE_POPUP,
    SET_TOKEN,
    SET_LANGUAGES,
    OPEN_MOBILE_COMPONENT,
    SET_PARTNERID,
    SET_GAMEID
} from '../constants';
/**
 * opens modal popups
 * @param {popupName} receives string as argument, which is the name of popup
 */
export const openPopup = popupName => ({
    type: OPEN_POPUP,
    data: {
        type: popupName,
    },
});

export const closePopup = () => ({
    type: CLOSE_POPUP
})

/**
 * opens message popups
 * @param {message} receives string as argument, which is the name of popup
 */
export const openMessagePopup = (message) => ({
    type: OPEN_POPUP,
    data: {
        type: 'messagePopup',
        data: {message},
    },
});

/**
 * setToken function
 * @param {data} receives object as argument, which is the token, and sends to reducer
 */

export const setToken = (data) => ({type: SET_TOKEN, data})
export const setLanguages = (lang) => ({type: SET_LANGUAGES, lang})
export const setPartnerId = (partnerId) => ({type: SET_PARTNERID, partnerId})
export const setGameId = (gameId) => ({type: SET_GAMEID, gameId})

/**
 * opens modal popups
 * @param {componentName} receives string as argument, which is the name of component
 */
export const openMobileComponent = componentName => ({
    type: OPEN_MOBILE_COMPONENT,
    data: componentName,
});