import React from 'react';
import 'flag-icon-css/css/flag-icon.css';

const RegionItem = props =>{
    return(
            <div onClick={props.onClick} className="region-item">
               {props.country}
                <div className={`flag-view flag-icon-c flag-icon-${props.code.toLowerCase()}`} ></div>
                <div className="arrow-view"></div>
            </div>
    )
}

export default RegionItem;