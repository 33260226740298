import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import Rounds from './rounds/index';
import Titles from './roundTitles/index';
import RoundTabs from './roundTabs/index';
import CompetitionType from './competitionType/index';
import Loading from "../../components/loading";
import RoundMobile from "../mobile/round-mobile"

import { getFilteredRounds } from "../../actions/roundActions";

const Lobby = (props) => {

    const [dates, setDates] = useState([])
    const [matches, setMatches] = useState(false)

    const query = "(min-width: 812px)"
    useEffect(() => {
        const media = window.matchMedia(query)
        if (media.matches !== matches) {
            setMatches(media.matches)
        }
        const listener = () => {
            setMatches(media.matches)
        }
        media.addListener(listener)
        return () => media.removeListener(listener)

    }, [matches, query]);


    useEffect(() => {
        getRoundStartDate()
    }, []);


    let getRoundStartDate = () => {
        const roundDates = [];
        const days = {
            0: "Sunday",
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday"
        };

        let formatDate = (date) => {
            let d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            return [day, month, year].join("/")
        };

        for (let i = -1; i < 9; i++) {
            let today = new Date();
            today.setDate(today.getDate() + i);
            roundDates.push({date: formatDate(today), day: days[today.getDay()]})
        }
        setDates(roundDates)
    };

    let getFilter = (e) => {
        props.getFilteredRounds(e.target.value)
    };

    return (
        <Fragment>
            {matches && <div id="web-lobby-wrapper" className="lobby-wrapper">
                <div className="t-list-view">
                    <RoundTabs/>
                    {props.competition ?
                        <Fragment>
                            <Titles coef={props.competition.CoeffsCount}/>
                            <CompetitionType
                            />
                        </Fragment>
                        : <Loading/>}
                    <Rounds/>
                </div>
            </div>}
            {!matches && <div id="mobile-lobby-wrapper" className="lobby-wrapper">
                <div className="t-list-view">
                    <div className="data-list">
                        <select  id="dates" onChange={getFilter}>
                            {dates.map((item, index) => {
                                return (
                                    <option value={item.date} key={index}>
                                        {item.date} / {item.day}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        {props.competition ? <CompetitionType/> : <Loading/>}
                    </div>
                    <RoundMobile />
                </div>
            </div>}
        </Fragment>
    );
};

export default connect(
    state => ({
        competition: state.rounds.competition,
    }),
    dispatch => bindActionCreators({
        getFilteredRounds
    }, dispatch)
)(Lobby);


