import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
//import {Translate} from "react-redux-i18n";

const Competition = props => {

    const addActiveClass = () => {
        let className = "";
        if (props.competitionId === props.itemId) className += "active";
        return className;
    }
    const onclick = () => {
        props.onClick();
    }

    return (
        <div onClick={onclick}
             className={`${props.type !== 'favorites' ? 'competition-title' : 'fav-competition'} ${addActiveClass()}`}>
            <p>{props.name}</p>
            <span>{props.selectedCount}</span>/
            <span>{props.numbersCount}</span>
            <div className="show-time">
                {props.type === "favorites" ?
                    <span className="icon-close" onClick={(e) => props.removeFavorite(props.itemId, e)}></span>
                    : <span className="show-time">{props.time}</span>}
            </div>
        </div>
    )
}
export default connect(
    state => ({
        competitionId: state.rounds.competitionId,
    }),
    dispatch => bindActionCreators({}, dispatch)
)(Competition)