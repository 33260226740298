import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withTranslation} from "react-i18next";

import RandomSelect from './RandomSelect';
import HotNumberItem from './HotNumberItem';
import Button from '../../../Button';
import {openPopup} from '../../../../actions/UI_actions';
import {clearBalls} from "../../../../actions/ballActions";
import {getFiveDroppedNumber, getHotNumber} from "../../../../actions/numberActions";

import Loading from "../../../loading";

class HotNumbers extends Component {

    state = {
        isLouder: false
    };

    handleClick = () => {
        this.setState({isLouder: true})
        this.props.getHotNumber(this.props.competitionId);
        this.props.openPopup('hotNumbersModal')
        this.setState({isLouder: false})

    }


    render() {

        const {t} = this.props

        return (
             <div className="hotNumbers-container">
                {this.props.fiveDroppedNumber.length > 0 ?
                    <div className="hotNumbers-wrapper">
                        <p>{t("hot-numbers")}</p>
                        <div className="hotNumbers-items-wrapper">
                            <HotNumberItem data={this.props.fiveDroppedNumber}/>
                        </div>
                        <div onClick={this.handleClick} className="h-show-more"></div>
                    </div> : <Loading width="25" height="25"/>}
                <div className="random-select-container">
                    <RandomSelect/>
                    <Button onClick={() => this.props.clearBalls()}
                            className={`button-view ${this.props.selectedBalls.length === 0 ? 'avoid-clicks' : ''}`}
                            name={t("clear")}/>
                </div>
            </div>
        )
    }
}

export default compose(
    withTranslation("translation"),
    connect(
        state => ({
            fiveDroppedNumber: state.droppedNumber.fiveDroppedNumber,
            selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
            competitionId: state.rounds.competitionId,
        }),
        dispatch => bindActionCreators({openPopup, clearBalls, getHotNumber, getFiveDroppedNumber}, dispatch)
    ))(HotNumbers);