import React from 'react'

const Button = ({name, className, span = '', onClick, disabled, textParams}) => (
    <button onClick={!disabled ? onClick : null} className={`${className} ${disabled ? 'avoid-clicks' : ''}`}>
       {name}
        {span && <span className="btn-span">{span}</span>}
    </button>
);

export default Button;


