import React from "react";
import StartTime from '../../../../helpers/startTime';
import BallAnimation from "./BallAnimation";

const BallAnimationWrapper = React.forwardRef((props, ref) => {
    return (
        <div className="ball-animation-container">
            <div className="ball-animation-wrapper">
                {props.animationStarted ? (
                    <BallAnimation ref={ref}/>
                ) : (
                    <div className="round-start-time">
                        <StartTime startTime={props.startTime}/>
                    </div>
                )}
            </div>
        </div>
    );
});
export default BallAnimationWrapper;
