import React  from "react";
import {useTranslation} from "react-i18next";


const LotteryTabs = ({count, type, ticketsMaxCount}) => {
    const { t } = useTranslation()
    return(
        <div className={type ? `${type} lottery_tabs` : 'lottery_tabs'}>
            <div className="text"> creating { t("tickets") }</div> 
            <div className="count">
                <span>{count} / {ticketsMaxCount}</span>
            </div>
        </div>
    )
}

export default LotteryTabs;