import React, {Fragment, useEffect} from "react"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {withTranslation} from "react-i18next"


import GetPlayerRoundsResultMobile from "../../../mobile/roundsResult/GetPlayerRoundsResultMobile"


import Home from "./../../../../components/home"
import FinishedTickets from "./../../rightMenuBox/rightMenuWeb/finishedTickets"
import WaitingTickets from "../../rightMenuBox/rightMenuWeb/createTickets"
import WinnerTickets from "../../rightMenuBox/rightMenuWeb/winnerTickets"

import {getAllWaitingTickets, getFinishedTickets, getUpDateTickets} from "../../../../actions/ticketActions"


export const RightMenuMobile = (props) => {

    useEffect(() => {
        if(props.token) {
            props.getUpDateTickets(props.token, props.partnerId, props.gameId)
            props.getAllWaitingTickets(props.token)
            props.getFinishedTickets(0)
        }

    },[props.token])

    const components = {
        home: () => (
            <Home
                mobileSize={props.mobileSize}
                component={props.component}
                competitionId={props.competitionId}
            />
        ),
        result: () => <GetPlayerRoundsResultMobile/>,
        waitingTickets: () => <WaitingTickets createdTickets={props.createdTickets}/>,
        finishedTickets: () => <FinishedTickets finishedTickets={props.ticketFinishedHistory}/>,
        winnerTickets: () => <WinnerTickets winnerTickets={props.winnerTickets}/>
    };

    const component = props.component;
    if (!component) return null;
    let Component = components[component];
    return Component ? (
        <Fragment>
            <Component/>
        </Fragment>
    ) : null
}

export default compose(
    withTranslation("translation"),
    connect(
        state => ({
            component: state.UI.component,
            mobileSize: state.mobile.mobileSize,
            competitionId: state.tickets.currentCreatingTicket.competitionId,
            ticketFinishedHistory: state.tickets.ticketFinishedHistory.tickets,
            winnerTickets: state.tickets.winnerTickets.tickets,
            createdTickets: state.tickets.createdTickets.tickets,
            token: state.user.token,
            partnerId: state.user.partnerId,
            gameId: state.user.gameId
        }),
        dispatch => bindActionCreators(
            {getAllWaitingTickets, getFinishedTickets, getUpDateTickets}, dispatch)
    )
)(RightMenuMobile)
