import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getFilteredRounds, getRound, getRounds} from "../../../actions/roundActions";


class Rounds extends Component {

    state = {
        isLoading: false,
        coifficent: []
    }

    componentDidUpdate(prevProps) {
        if(this.props.coifficent !== prevProps.coifficent) {
            this.setState({
                coifficent: this.props.coifficent
            })
        }
        if (prevProps.competitionsAll !== this.props.competitionsAll) {
             return true
         }
    }

    activeRound(round, index) {
        let id = round.Id
        let className = ""
        if (this.props.selectedRoundId === id && this.props.rounds.indexOf(this.props.round) !== -1) className += "active"
        if (this.props.rounds.indexOf(this.props.round) === -1 && index === 0) {
            className += "active";
            this.props.getRound(round)
        }
        return className
    }

    scrolling = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && this.props.allRoundsList.length + 1 <= this.props.totalCount) {
            this.setState({isLoading: true});
            let item = {...this.props.round, ...this.props.regionId};
            if (!this.props.selectedDate) {
                this.props.getRounds(item);
            } else {
                this.props.getFilteredRounds();
            }
            this.setState({isLoading: false});
        }
    }

    render() {
        return (
            <div onScroll={this.scrolling} className="single-list-row-container">
                <div className="single-list-row">
                    {this.props.allRoundsList.length && this.props.allRoundsList.map((round, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => this.props.getRound(round)}
                                className={`single-list ${this.activeRound(
                                    round,
                                    index
                                )}`}
                            >
                                <span className="icon-sign-green-right"></span>
                                <ul>
                                    <li key={index}>{round.RoundNumber}</li>
                                    <li key={index + 1}>{round.StartTime}</li>
                                    {this.state.coifficent.map((elem, key) => {
                                        return <li key={key}>{elem}</li>
                                    })
                                    }
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        rounds: state.rounds.allRoundsList,
        competitionId: state.rounds.competitionId,
        selectedRoundId: state.rounds.selectedRoundId,
        round: state.rounds.round,
        allRoundsList: state.rounds.allRoundsList,
        regionId: state.rounds.RegionId,
        totalCount: state.rounds.totalCount,
        selectedDate: state.rounds.selectedDate,
        competitionsAll: state.competitions ? state.competitions.competitionsAll : [],
        coifficent: state.quickLotteries.coifficent
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRound,
            getRounds,
            getFilteredRounds,
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rounds);
