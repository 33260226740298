import React, { useEffect } from "react"
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import Collapsible from "react-collapsible"


import RegionItem from "../fullRegion/RegionItem";
import FullRegionInfo from "../fullRegion";
import GamesList from "../gamesList";
import Competition from "../gamesList/Competition";
import {
    getCount,
    getCountRounds,
    getCurrentRegionId,
    getRounds,
    getStatusRound
} from "../../../../actions/roundActions";
import Title from "../Title";
import {getDroppedNumber, getFiveDroppedNumber} from "../../../../actions/numberActions";
import {getBetAmountRange, getWinnerTickets} from "../../../../actions/ticketActions";

const Lotteries = (props) => {

    useEffect(() => {
        if (props.competitionsAll !== null && props.winnerTickets.length === 0 && props.count === null) {
            let params = props.competitionsAll[0]
            props.getFiveDroppedNumber(params)
            if (props.token) {
                props.getBetAmountRange(props.token)
                props.getDroppedNumber(params)
                props.getStatusRound(params)
            }
            props.getCount(1)
            props.getRounds(params);
            props.getWinnerTickets()
        }

    }, [props.competitionsAll])


    let getRoundData = (elm) => {
        props.getCountRounds(1)
        props.getRounds(elm)
        props.getFiveDroppedNumber(elm)
        props.getDroppedNumber(elm)
        props.getStatusRound(elm)
    }

    return (
        <div className='left-nav-view'>
            <ul>
                <div className="float-width">
                    <Collapsible
                        open={true}
                        trigger={
                            <Title type="nationalKeno"
                                   name="National Lottery"
                            />
                        }
                    >
                        {props.competitionsAll !== null && props.regions && props.regions.map((item, index) => {
                                return (
                                    <FullRegionInfo key={index}>
                                        <Collapsible
                                            trigger={
                                                < RegionItem
                                                    country={item.Name}
                                                    code={item.Code}
                                                />
                                            }
                                        >
                                            <GamesList competitions={props.competitionsAll}>
                                                {props.competitionsAll && props.competitionsAll.map((elem, id) => {
                                                    if (elem.RegionId === item.Id) {
                                                        return (
                                                            <Competition
                                                                onClick={() => getRoundData(elem)}
                                                                getRounds={props.getRounds}
                                                                key={id}
                                                                name={elem.Name}
                                                                selectedCount={elem.MaxSelectedNumbersCount}
                                                                numbersCount={elem.NumbersCount}
                                                                itemId={elem.Id}
                                                            />
                                                        )
                                                    }
                                                })}

                                            </GamesList>
                                        </Collapsible>
                                    </FullRegionInfo>
                                )
                            }
                        )
                        }
                    </Collapsible>
                </div>

            </ul>
        </div>
    )
}


export default connect(state => ({
    competitionsAll: state.competitions.competitionsAll,
    regions: state.regions.regionsList,
    token: state.user.token,
    fiveDroppedNumber: state.droppedNumber.fiveDroppedNumber,
    winnerTickets: state.tickets.winnerTickets.tickets,
    count: state.rounds.count
}), dispatch => bindActionCreators({
    getRounds, getFiveDroppedNumber, getDroppedNumber,
    getStatusRound, getCurrentRegionId, getCountRounds,
    getBetAmountRange, getWinnerTickets, getCount
}, dispatch))(Lotteries)
