import React from "react";
import "flag-icon-css/css/flag-icon.css";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import countrySelector from '../../../selectors/countrySelector';
import {addRemoveFavorites, getFavoriteCompetitions} from '../../../actions/favoriteActions';

const CompetitionType = (props) => {
    return (
        <ul className="info-c-type">
            <li>
                <div className="type-item favorites">
                    <p className={`flag-view flag-icon-c flag-icon-${
                        props.countryCode ? props.countryCode.toLowerCase() : null
                    }`}/>
                    <p>
                        <span>{props.competition.Name}</span>
                        <span>
                     {props.competition.MaxSelectedNumbersCount}/{props.competition.NumbersCount}
                  </span>
                    </p>
                    {props.token &&
                    <div className={props.competition.IsFavorite === true ? 'favorite-view active' : 'favorite-view'}
                         onClick={() => props.addRemoveFavorites(props.competition.Id)}/>}
                    {/* <div className="arrow-view"></div> */}
                </div>
            </li>
        </ul>
    )
}
export default connect(
    state => ({
        competition: state.rounds.competition,
        countryCode: countrySelector(state),
        favorites: state.favorites.favoritesList,
        token: state.user.token
    }),
    dispatch => bindActionCreators({
        addRemoveFavorites,
        //getFavoriteCompetitions
    }, dispatch)
)(CompetitionType);

