export const OPEN_POPUP = 'OPEN_POPUP';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const GET_COMPETITIONS_ARRAY = "GET_COMPETITIONS_ARRAY";
export const GET_ROUNDS = "GET_ROUNDS";
//export const COUNTRIES_FETCHED = "COUNTRIES_FETCHED";
//export const ADD_FAVORITES = "ADD_FAVORITES";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const GET_ROUND = 'GET_ROUND';
export const GET_FILTERED_ROUNDS = "GET_FILTERED_ROUNDS";
//export const REMOVE_FAVORITE_BY_ID = "REMOVE_FAVORITE_BY_ID";
export const GET_COMPETITION = 'GET_COMPETITION';
export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_QUICK_LOTTERIES = 'GET_QUICK_LOTTERIES';
export const GET_SYSTEM_TYPES = 'GET_SYSTEM_TYPES';
export const HANDLE_BALL_CLICK = "HANDLE_BALL_CLICK";
export const CLEAR_BALLS = "CLEAR_BALLS";
export const DELETE_CREATING_TICKET = "DELETE_CREATING_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const SET_SELECTED_SYSTEM_TYPE = "SET_SELECTED_SYSTEM_TYPE";
export const GET_PROBABILITY = "GET_PROBABILITY";
export const GET_TAX_AND_STAKE = "GET_TAX_AND_STAKE";
export const GET_RANDOM_SELECTED_MAX_VALUE = "GET_RANDOM_SELECTED_MAX_VALUE";
export const GET_CREATED_TICKETS = "GET_CREATED_TICKETS";
export const CREATED_TICKETS_TOTAL_COUNT = "CREATED_TICKETS_TOTAL_COUNT";
export const SET_BET = "SET_BET";
export const ADD_CREATED_TICKET = "ADD_CREATED_TICKET";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const GET_TICKETS_HISTORY = "GET_TICKETS_HISTORY";
export const GET_PLAYER_ROUNDS = "GET_PLAYER_ROUNDS";
export const SET_TOKEN = "SET_TOKEN";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const DELETE_SEARCH_RESULTS = "DELETE_SEARCH_RESULTS";
export const FILTER_BY_SELECT = "FILTER_BY_SELECT";
export const IS_CLOSE = "IS_CLOSE";
export const GET_DROPPED_NUMBER = "GET_DROPPED_NUMBER";
export const GET_ROUNDS_COUNT = "GET_ROUNDS_COUNT";
export const GET_CURENT_REGION_ID = "GET_CURENT_REGION_ID";
export const  GET_HOT_NUMBER = " GET_HOT_NUMBER";
//export const  SET_ROUND_COUNT = " SET_ROUND_COUNT";
export const  SET_TICKET_COUNT = " SET_TICKET_COUNT";
export const  GET_FIVE_DROPPED_NUMBER = "GET_FIVE_DROPPED_NUMBER";
export const  GET_FINISHED_TICKETS = "GET_FINISHED_TICKETS";
export const  GET_COMPETITION_ARR = "GET_COMPETITION_ARR";
//export const  DELETE_FINISHED_TICKET = "DELETE_FINISHED_TICKET";
export const  STATUS_ROUND = "STATUS_ROUND";
export const  DELETE_ROUND = "DELETE_ROUND";
export const ANIMATION_END = "ANIMATION_END";
export const THE_SAME_NUMBER = "THE_SAME_NUMBER";
export const GAME_END = "GAME_END";
export const UPDATE_TICKETS = "UPDATE_TICKETS";
export const SET_TOTAL_TICKET = "SET_TOTAL_TICKET";
export const DELETE_TICKETS = "DELETE_TICKETS";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const GET_ROUND_STATE = "GET_ROUND_STATE";
export const ADD_FINISHED_TICKETS = "ADD_FINISHED_TICKETS";
export const GET_BET_AMOUNT_RANGE= "GET_BET_AMOUNT_RANGE";
export const URL = "https://nlotto-api-stage.betcoapps.com/en/1/api/main/apirequest";
//export const URL = "http://172.21.77.26:8080/en/1/api/main/apirequest";
export const CHECK_MOBILE = "CHECK_MOBILE";
export const OPEN_MOBILE_COMPONENT = 'OPEN_MOBILE_COMPONENT';
export const SET_PARTNERID = 'SET_PARTNERID';
export const SET_GAMEID = 'SET_GAMEID';
export const GET_COEFFICENTS = 'GET_COEFFICENTS';
export const CLEAR_UPDATE_TICKETS = 'CLEAR_UPDATE_TICKETS';
export const CLEAR_DROPPED_NUMBER = 'CLEAR_DROPPED_NUMBER';
export const CLEAR_SAME_GAME = 'CLEAR_SAME_GAME';
export const ACTIVE_BUTTON = 'ACTIVE_BUTTON';
export const SET_ACTIVE_BUTTON = 'SET_ACTIVE_BUTTON';
export const CHANGE_COMPETITIONID = 'CHANGE_COMPETITIONID';
export const GET_WINNER_TICKETS = 'GET_WINNER_TICKETS';
export const GET_COUNT = 'GET_COUNT';
export const SET_INDEX = 'SET_INDEX';
