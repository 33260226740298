import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Collapsible from 'react-collapsible';
import {openPopup} from '../../../actions/UI_actions';
//import HotNumber from '../../../components/centerColumn/balls/hotNumbers/HotNumber';
import Ticket from '../../../components/rightColumn/rightMenuBox/ticketWrapper/ticket';
import {getTicketsHistory} from "../../../actions/ticketActions";
import ShowCurrency from "../../../helpers/ShowCurrency";
//import countrySelector from '../../../selectors/countrySelector';
import Loading from "../../../components/loading";
import {getPlayerRounds} from "../../../actions/roundActions";
//import RoundsResultFilter from './RoundsResultFilter';

class GetPlayerRoundsResultMobile extends Component {
     state = {
         isLoading: false
     };

    scrolling = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && this.props.roundsHistory && this.props.roundsHistory.length + 1 < this.props.totalCount) {
            this.setState({isLoading: true});
            this.props.getPlayerRounds()
            this.setState({isLoading: false});
        }
    };

    render() {
        return (
            //<h1 style={{color:"white"}}>player rounds result mobile </h1>
            <div className="rounds-result-mobile">
                {/*      <RoundsResultFilter />*/}
                <div className="modal-header-w">
                    {/*   <span className={`flag-icon-c flag-icon-${this.props.countryCode ? this.props.countryCode.toLowerCase() : null}`}></span>*/}
                    <h3>
                        {this.props.competition.Name} {this.props.competition.MaxSelectedNumbersCount}/{this.props.competition.NumbersCount}
                    </h3>
                </div>

                <div className="modal-content-wrapper">
                    <div className="modal-content-wrapper">

                        <div className="t-list-view">
                            <ul className="info-t-title">
                                <div className="title">RoundId</div>
                                <div className="title">Time</div>
                                <div className="title">Date</div>
                                {/* <div className="title">Numbers</div> */}
                            </ul>
                            <div className="single-list-row" onScroll={this.scrolling}>
                                {this.props.roundsHistory && this.props.roundsHistory.length > 0
                                    ? this.props.roundsHistory.map((round, index) => {
                                        return <div className="single-list" key={index}>
                                            <Collapsible trigger={
                                                //in getTicketsHistory should get roundId
                                                <ul onClick={round.IsTicketExist ? () => this.props.getTicketsHistory(round.RoundId, this.props.counter) : null}>
                                                    <li> {round.RoundId}</li>
                                                    <li key={index + 1}> {round.StartTime}</li>
                                                    {(() => {
                                                        let data = new Date(round.StartDate).getDate();
                                                        let mount = new Date(round.StartDate).getMonth();
                                                        let year = new Date(round.StartDate).getFullYear();
                                                        return <li>{data}/{mount}/{year}</li>
                                                    })()}

                                                     <li>
                                                        <div className={round.IsTicketExist ? "arrow-view" : null}></div>
                                                    </li>
                                                </ul>
                                            }>
                                                {/* <ul>
                                                    <li key ={index+1} className="width-li">
                                                            {round.DroppedNumbers.map((ball, index) => {
                                                                return <HotNumber key={index} number={ball}/>
                                                            })}
                                                        </li>
                                                        </ul> */}
                                                {round.IsTicketExist && this.props.ticketHistory && this.props.ticketHistory.length > 0 ? this.props.ticketHistory.map((ticket, index) => {

                                                        return <Ticket
                                                            name={ticket.CompetitionName}
                                                            key={index}
                                                            ticketNumber={ticket.TicketNumber}
                                                            balls={ticket.Balls}
                                                            matchedBalls={ticket.MatchedBalls}
                                                            winAmount={ticket.WinAmount}
                                                            betAmount={ticket.BetAmount}
                                                        />
                                                    })
                                                    : round.IsTicketExist ? <Loading width="40" height="40"/> : null}
                                                {round.IsTicketExist && this.props.ticketHistory && this.props.ticketHistory.length > 0 ? this.props.ticketHistory.map((ticket, index) => {
                                                        return <div className="ticket-item-wrapper bg-closed" key={index}>
                                                            <div className="ticket-item">
                                                                <div>
                                                                    <div className="ticket-number-wrapper">
                                                                                <span
                                                                                    className='country'>Total Bet: {ticket.WinAmount}</span>
                                                                        <ShowCurrency/>
                                                                    </div>
                                                                    <div className="ticket-number-wrapper">
                                                                                <span
                                                                                    className='country'>Total Win: {ticket.BetAmount}</span>
                                                                        <ShowCurrency/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                    : null}
                                            </Collapsible>
                                        </div>
                                    }) : <Loading width="40" height="40"/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        roundsHistory: state.rounds.roundsHistory.allList,
        totalBet: state.tickets.ticketsHistory.totalBet,
        totalWin: state.tickets.ticketsHistory.totalWin,
        competition: state.rounds.competition,
        ticketHistory: state.tickets.ticketsHistory.allTickets,
        //countryCode: countrySelector(state),
        totalCount: state.rounds.roundsHistory.totalCount,
        counter: state.tickets.ticketsHistory.counter
    }),
    dispatch => bindActionCreators({openPopup, getTicketsHistory, getPlayerRounds}, dispatch),
)(GetPlayerRoundsResultMobile);