import React, {Fragment, useState, useEffect} from "react"
import LeftColumn from "./leftColumn";
import CenterColumn from "./centerColumn";
import isMobile from "../helpers/mobile";
import CreatingTickets from "./rightColumn/rightMenuBox/rightMenuMobile/CreatingTickets";
import {connect} from "react-redux";

const Home = (props) => {
    const [bool, setBool] = useState(false);

    useEffect(() => {
        if (props.creatingTicket !== null) {
            setBool(true)
        }
    }, [props.creatingTicket])


   /* const handleClick = e => {
        e.preventDefault()
        if(props.token) {
            setBool(!bool)
        }
    }
*/
    return (
        <Fragment>
            <LeftColumn/>
            <CenterColumn/>
            {!(isMobile() || props.mobileSize) ? null :
                <div className="mobile-bottom">
                   {/* <button onClick={(e) => handleClick(e)}>Create Ticket</button>*/}
                    {bool ? <CreatingTickets/> : null}
                </div>}
        </Fragment>
    )
}

export default connect(state => ({
    token: state.user.token
}), null)(Home)