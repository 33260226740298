import isMobile from "./mobile";

export default function resize() {
    let el = document.getElementById('root');
    let styles = {};

    let width = window.innerWidth;
    let height = window.innerHeight;
    if (((width < 1920 || height < 969) && !isMobile()) && window.innerWidth > 812)  {
        let transX = parseInt(width) / 1920;
        let transY = parseInt(height) / 969;


        styles = {
            ...styles,
            'position': 'absolute',
            'transform-origin': 'left top',
            'transform': 'scale(' + transX + ',' + transY + ')',
            'width': '1920px',
            'height': '969px'
        };

        for (let key in styles) {
            el.style[key] = styles[key];
        }
    } else {
        el.removeAttribute('style');
    }
}

window.onresize = resize;
