import React, { PureComponent, Fragment} from "react"; 
import {connect} from "react-redux"; 
import { bindActionCreators } from "redux"; 
import { closePopup } from "../actions/UI_actions"; 
import Popup from "./Popup";
import GetPlayerRoundsResult from './GetPlayerRoundsResult';
import HotNumbersModal from './HotNumbersModal';

const popups = {
    messagePopup: props => (
        <Popup 
            headerText={`Information`}
            message={props.message}
            closePopup={props.closePopup}

        />
    ),
    getPlayerRoundsResult: () =>   <GetPlayerRoundsResult />,
    hotNumbersModal: () => <HotNumbersModal />
};

class Popups extends PureComponent {
    // closePopup = e => {
    //     if (e.target === e.currentTarget) this.props.closePopup(); 
    // }
    render(){
        const popup = this.props.popup;
        if(!popup) return null;
        let Component = popups[popup.type]; 
        return Component ? (
            <Fragment>
                <Component {...popup.data} closePopup={this.props.closePopup} /> 
            </Fragment>
        ) : null
    }
}

function mapStateToProps(state) {
    return {
        popup: state.UI.popup,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            closePopup
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Popups);

