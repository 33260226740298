import React, {Fragment} from "react"
import Ticket from "../ticketWrapper/ticket";


const FinishedTickets = (props) => {
    let scrolling = (e) => {
        if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && props.finishedTickets.length < props.totalCount) {
            //this.setState({isLoading: true});
            props.getFinishedTickets()
                .then(() => {
                    //this.setState({isLoading: false})
                })
        }
    }

    return (
        <Fragment>
            {!props.finishedTickets.length > 0 ? null :
                <div id="finished-tickets" className="finished-tickets created-tickets-wrapper " onScroll={scrolling}>
                    <div className='ticket-text'>Finished Tickets
                        {/* <span> Total - { props.finishedTickets.length}</span> */}
                    </div>
                        {props.finishedTickets.map((item, index) => {
                            return <Ticket deleteIcon={false} key={index} id={item.Id} name={item.Name}
                                           totalWin={item.WinAmount} totalBet={item.BetAmount}
                                           balls={item.Balls} isState={item.State}
                                           matchedBalls={item.MatchedBalls} ticketNumber={item.TicketNumber}
                                           loose={item.WinAmount === 0 ? 0 : ""} systemType={item.SystemType}/>
                        })}

                </div>}
        </Fragment>
    )
}

export default FinishedTickets