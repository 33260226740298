import { createSelector } from "reselect";

const getRegions = state => state.regions.regionsList;
const getCompetition = state => state.rounds.competition;

const countrySelector = createSelector(
   [getRegions, getCompetition],
   (regions, competition) => {
      if (competition) {
          for (let item in regions) {
              if (regions[item].Id === competition.RegionId) {
                 return regions[item].Code;
              } 
           }
      }
   }
);
export default countrySelector;
