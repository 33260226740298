import {
    GET_ROUNDS, GET_ROUND, GET_FILTERED_ROUNDS,
    GET_COMPETITION, GET_PLAYER_ROUNDS, GET_ROUNDS_COUNT,
    GET_CURENT_REGION_ID, STATUS_ROUND, DELETE_ROUND, GET_ROUND_STATE, GET_COUNT, SET_INDEX
} from "../constants";

const ACTION_HANDLERS = {
    [GET_ROUNDS]: (state, action) => {
        if (state.selectedDate) {
            state.allRoundsList = [];
        }
        return {
            ...state,
            roundsList: action.receivedRounds.Data,
            competitionId: action.competitionId,
            date: action.receivedRounds.Data,
            allRoundsList: state.competitionId === action.competitionId ? state.allRoundsList.concat(action.receivedRounds.Data) : action.receivedRounds.Data,
            counter: action.counter ? action.counter : state.counter,
            totalCount: action.receivedRounds.TotalCount,
            selectedDate: null
        }
    },
    [GET_ROUND]: (state, action) => {
        return {
            ...state,
            round: action.round,
            selectedRoundId: action.round.Id,
        }
    },
    [GET_FILTERED_ROUNDS]: (state, action) => {
        if (action.selectedDate && !state.selectedDate) {
            state.allRoundsList = [];
            return {
                ...state,
                roundsList: action.roundDate,
                allRoundsList: action.roundDate,
                /* roundsList: state.allRoundsList.filter(item => {
                     return item.StartDate === action.roundDate
                 }),*/
                selectedDate: action.selectedDate,
                totalCount: action.totalCount

            }
        } else if (state.selectedDate === action.selectedDate) {
            return {
                ...state,
                roundsList: action.roundDate,
                allRoundsList: [...state.allRoundsList, ...action.roundDate],
                selectedDate: action.selectedDate,
                totalCount: action.totalCount
            }
        } else {
            state.allRoundsList = [];
            return {
                ...state,
                roundsList: action.roundDate,
                allRoundsList: action.roundDate,
                selectedDate: action.selectedDate,
                totalCount: action.totalCount
            }
        }
    },
    [GET_COMPETITION]: (state, action) => {
        return {
            ...state,
            competition: action.competition,
            randomSelectedMaxValue: action.competition.RandomSelectedMaxValue
        }
    },
    [GET_PLAYER_ROUNDS]: (state, action) => {
        return {
            ...state,
            roundsHistory: {
                allList: !state.roundsHistory.allList ? action.payload.rounds : [...state.roundsHistory.allList, ...action.payload.rounds],
                totalCount: action.payload.totalCount,
            }
        }
    },
    [GET_ROUNDS_COUNT]: (state, action) => {
        return {
            ...state,
            counter: action.counter
        }
    },
    [GET_CURENT_REGION_ID]: (state, action) => {
        return {
            ...state,
            RegionId: {RegionId: action.RegionId}
        }
    },

    [STATUS_ROUND]: (state, action) => {
        return {
            ...state,
            statusRound: {
                roundId: action.payload.roundId,
                roundState: action.payload.roundState
            }
        }
    },
    [DELETE_ROUND]: (state, action) => {
        return {
            ...state,
            allRoundsList: state.allRoundsList.filter(item => item.Id !== state.statusRound.roundId)
        }
    },
    [GET_ROUND_STATE]: (state, action) => {
        return {
            ...state,
            noActiveRound: {
                roundState: action.payload.roundState,
                roundId: action.payload.roundId
            }
        }
    },
    [GET_COUNT]: (state, action) => {
        return {
            ...state,
            count: action.payload
        }
    },
    [SET_INDEX]: (state, action) => {
        return {
            ...state,
            currentIndex: action.payload
        }
    }
}

const initialState = {
    roundsList: [],
    currentIndex: 0,
    competitionId: null,
    competition: null,
    date: null,
    round: null,
    selectedId: null,
    allRoundsList: [],
    selectedDate: null,
    randomSelectedMaxValue: 0,
    roundsHistory: {
        allList: [],
        rounds: null,
        totalCount: null,
    },
    counter: 0,
    RegionId: [],
    totalCount: null,
    statusRound: {
        roundId: null,
        roundState: null
    },
    noActiveRound: {
        roundId: null,
        roundState: null
    },
    count: null
};


const roundReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};


export default roundReducer;
