import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withTranslation} from "react-i18next";
import {getRandomSelectedMaxValue} from "../../../../actions/ballActions";
import Button from "../../../Button";


class RandomSelect extends Component {
    state = {
        maxValue: this.props.randomSelectedMaxValue,
        currentMaxValue: 0
    };

    fun() {
        if ( this.state.currentMaxValue === 0 && this.state.currentMaxValue !== this.props.randomSelectedMaxValue) {
            this.setState(() => ({currentMaxValue: this.props.randomSelectedMaxValue}));
        }
        if (this.state.maxValue !== this.props.randomSelectedMaxValue) {
            this.setState(() => ({
                currentMaxValue: this.props.randomSelectedMaxValue,
                maxValue: this.props.randomSelectedMaxValue
            }));

        }
    }

    componentDidMount() {
        this.fun()
    }

    componentDidUpdate() {
        this.fun()
    }


    handleRandomSelectedMaxValue(type) {
        if (type === "decrement" && this.state.currentMaxValue > 1) {
            this.setState({currentMaxValue: this.state.currentMaxValue - 1});

        }
        if (
            type === "increment" &&
            this.state.currentMaxValue < this.props.randomSelectedMaxValue
        ) {
            this.setState({currentMaxValue: this.state.currentMaxValue + 1});
        }
    }

    getRandomSelectedMaxValue = () => {
        let randomBallsArr = [];
        let random = null;
        while (randomBallsArr.length < this.state.currentMaxValue) {
            random = Math.ceil(
                Math.random() * this.props.competition.NumbersCount
            );
            if (randomBallsArr.indexOf(random) === -1) {
                randomBallsArr.push(random);
            }
        }
        /**
         * @param randomBallsArr sending to store
         */
        this.props.getRandomSelectedMaxValue({
            randomBallsArr: randomBallsArr,
            competitionId: this.props.competition.Id,
            regionId: this.props.competition.RegionId
        });
    };

    render() {
        const {t} = this.props
        return (
            <div className="random-select-wrapper">
                <div className="random-select">
                    <div
                        onClick={() => this.handleRandomSelectedMaxValue("decrement")}
                        className={`decrement ${
                            this.state.currentMaxValue === 1 ||
                            this.state.currentMaxValue === 0
                                ? "avoid-clicks"
                                : ""
                        }`}
                    ></div>
                    <div className="selected-number">
                        <span>{this.state.currentMaxValue}</span>
                    </div>
                    <div
                        onClick={() => this.handleRandomSelectedMaxValue("increment")}
                        className={`increment ${
                            this.state.currentMaxValue ===
                            this.props.randomSelectedMaxValue ||
                            this.state.currentMaxValue === 0
                                ? "avoid-clicks"
                                : ""
                        }`}
                    ></div>
                </div>
                <Button
                    onClick={this.getRandomSelectedMaxValue}
                    className={`button-view ${ (this.props.statusRound.roundId === this.props.selectedRoundId) ||
                          (this.props.noActiveRound.roundState === 1 && this.props.selectedRoundId === this.props.noActiveRound.roundId)
                        ? "avoid-clicks" : ""}`}
                    name={t("random-select")}
                />
            </div>
        );
    }
}

export default compose(
    withTranslation("translation"),
    connect(
        state => ({
            statusRound: state.rounds.statusRound,
            selectedRoundId: state.rounds.selectedRoundId,
            noActiveRound: state.rounds.noActiveRound,
            randomSelectedMaxValue: state.rounds.randomSelectedMaxValue,
            competition: state.rounds.competition
        }),
        dispatch =>
            bindActionCreators(
                {
                    getRandomSelectedMaxValue
                },
                dispatch
            )
    ))(RandomSelect);
