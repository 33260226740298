import React, {Component} from 'react';
import BallItems from './ballItems/index';
import HotNumbers from './hotNumbers/index';


class Balls extends Component {

    render(){
        return(
            <div className="balls-container">
                {/* <PlacedBetsInfo /> */}
                <BallItems />
                <HotNumbers/>
            </div>
        )
    }
}
export default Balls;