import React, {useState, useEffect, useRef} from 'react';
import Button from '../../../../Button';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SystemTypeItem from "./SystemType";
import {
    setSelectedSystemTypeId,
    getTaxAndStake,
    createTicket,
    setBet,
    setActiveButton
} from "../../../../../actions/ticketActions";
import {openPopup, openMessagePopup} from '../../../../../actions/UI_actions';
import ShowCurrency from "../../../../../helpers/ShowCurrency";

const TicketMenu = (props) => {

    const [active, setActive] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [message, setMessage] = useState("")
    const [bet, setBet] = useState(props.minBet)
    const [disableButton, setDisableButton] = useState(false)
    const [matches, setMatches] = useState(false)

    let getInput = useRef(null)

    const query = "(min-width: 812px)"
    useEffect(() => {
        const media = window.matchMedia(query)
        if (media.matches !== matches) {
            setMatches(media.matches)
        }
        const listener = () => {
            setMatches(media.matches)
        }
        media.addListener(listener)
        return () => media.removeListener(listener)

    }, [matches, query]);


    useEffect(() => {
        if (props.bet !== "" && props.selectedSystemId !== null) {
            props.getTaxAndStake(bet, props.competitionId, props.selectedSystemId)
        }
    }, [props.selectedSystemId])

    useEffect(() => {
        if (props.activeButton === true) {
            setActive(true)
        }
    }, [props.activeButton])


    let setSystemType = (e) => {
        let selectedSystemId = e.target.options[e.target.selectedIndex].attributes[0].value;
        props.setSelectedSystemTypeId(selectedSystemId)
    }

    let betRegex = /^(|0|0\.|0\.[0-9]+|[1-9]\d*\.?\d*)$/;
    //betRegex = /^\d*\.?\d*$/
    // let getInput = el => (input = el);


    let change = () => {
        setMessage("")
        setErrorMessage({errorMessage: false})
        setBet(getInput.current.value)
        const value = getInput.current.value;
        const match = value.match(betRegex);
        props.setBet(value);
        if (betRegex.test(value))
            if (match) getInput.current.value = match[0];
        let check = getInput.current.value.toString();
        if (getInput.current.value > props.maxBet) {
            setErrorMessage({errorMessage: true})
            setDisableButton({disableButton: true})
            setMessage({message: `Bet must be smaller than or equal to ${props.maxBet}`})
            return false
        } else if (getInput.current.value === '' || getInput.current.value < +props.minBet) {
            setErrorMessage({errorMessage: true})
            setDisableButton({disableButton: true})
            setMessage({message: `Bet must be bigger than or equal to ${props.minBet}`})
            props.setBet(props.maxBet)
            return false

        } else if (check.indexOf('.') > -1 && check.indexOf('.') === check.length - 1) {
            props.setBet(value)
            setDisableButton({disableButton: true})
            setErrorMessage({errorMessage: true})
            setMessage({message: `invalidInput`})
            return false
        } else {
            props.setBet(match ? match[0] : value)
            props.getTaxAndStake(getInput.current.value, props.competitionId, props.selectedSystemId);
        }
    };

    let setMaxBet = () => {
        console.log("max bet for player")
    }

    let create = e => {
        e.preventDefault();
        setActive(false)
        props.setActiveButton()
        const data = {
            "RoundId": props.selectedRoundId,
            "Numbers": props.selectedBalls,
            "SystemTypeId": props.selectedSystemId,
            "BetAmount": bet
        }
        props.createTicket(data);
    };

    return (
        <div className="ticket-full-menu">
            {/*    {matches && <div className="percent-bar-wrapper">
                <span>Chances to win</span>
                <div className="rectangle">
                     <Box className="percent-bar green-bar" style={{ width: `${this.props.probability}%`, transition: `all .4s ease-in-out ` }} />
                    <div className="percent-bar green-bar"
                         style={{width: `${props.probability}%`, transition: `all .3s ease-out `}}></div>
                </div>
            </div>}*/}

            {matches && <div className="percent-bar-wrapper">
                <span className="span"><strong>{props.selectedRoundStartDate.slice(5)} </strong></span>
                <span className='ticket'><strong>{props.selectedRoundStartTime}</strong></span>
                {/* <div className="rectangle">
                        <Box className="percent-bar yellow-bar" style={{ width: `${this.state.width}%` }} />
                    </div> */}
            </div>}

            <div className="system-wrapper">
                <div>System</div>
                <div className="system-item">
                    <div>
                        <select name="systemTypes" onChange={setSystemType}>
                            {props.systemTypes.length !== 0 ?
                                props.systemTypes.map((item, index) => {
                                    return (
                                        <SystemTypeItem
                                            key={index}
                                            id={item.Id}
                                            value={item.Id}
                                            matchesCount={item.SelectedMatchesCount}
                                            numbersCount={item.SelectedNumbersCount}
                                        />
                                    )
                                }) : null
                            }
                        </select>
                        <span className="arrow"></span>
                    </div>
                </div>
            </div>

            <div className="possible-winnings bet">
                <div className="amount-container">
                    <p className="maxBet" onClick={setMaxBet}>
                        <span>Max</span>
                    </p>
                    <div>
                        <input
                            onChange={change}
                            ref={getInput}
                            type="text"
                            max={props.maxBet}
                            min={props.minBet}
                            value={bet}
                            placeholder="Bet"
                        />
                        <ShowCurrency/>
                    </div>

                </div>
                {errorMessage && <p className="bet-err-text">
                    <span>{message.message}</span>
                    {/* <span>{bet}</span> */}
                </p>}

                {/*   <div className="data-container">
                    <p>Stake</p>
                    <div>
                        <span>{props.stake ? props.stake : 0}</span>
                        <span><ShowCurrency/></span>
                    </div>
                </div>*/}
                {/*   <div className="data-container">
                    <p>Tax</p>
                    <div>
                        <span>{props.tax ? props.tax : 0}</span>
                        <span><ShowCurrency/></span>
                    </div>
                </div>*/}
                <div className="data-container">
                    <p>Win</p>
                    <div>
                        <span>{props.winAmount}</span>
                        <span><ShowCurrency/></span>
                    </div>
                </div>
            </div>
            <Button onClick={create} name={`${active ? "create ticket" : "your ticket created"}`}
                    className={`${disableButton || props.selectedBalls.length === 0 || props.statusRound.roundId === props.selectedRoundId || props.totalTicket === props.ticketsMaxCount || !active ? 'avoid-clicks' : ''} button-view`}
            />
        </div>
    );

}

function mapStateToProps(state) {
    //let length = state.tickets.currentCreatingTicket.selectedBalls;
    return {
        competitionId: state.rounds.competitionId,
        systemTypes: state.tickets.currentCreatingTicket.systemTypes,
        selectedSystemId: state.tickets.currentCreatingTicket.selectedSystemId,
        // probability: length !== 0 ? state.tickets.currentCreatingTicket.probability : 0,
        // tax: state.tickets.currentCreatingTicket.tax,
        //stake: state.tickets.currentCreatingTicket.stake,
        winAmount: state.tickets.currentCreatingTicket.winAmount,
        selectedRoundId: state.rounds.selectedRoundId,
        selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
        bet: state.tickets.currentCreatingTicket.bet,
        selectedRoundStartDate: state.rounds.round ? state.rounds.round.StartDate : null,
        selectedRoundStartTime: state.rounds.round ? state.rounds.round.StartTime : null,
        statusRound: state.rounds.statusRound,
        totalTicket: state.tickets.totalTicket,
        ticketsMaxCount: state.competitions.competitionsAll ? state.competitions.competitionsAll[0].TicketsMaxCount : null,
        maxBet: state.tickets.getBetAmountRange ? state.tickets.getBetAmountRange.MaxBet : null,
        minBet: state.tickets.getBetAmountRange ? state.tickets.getBetAmountRange.MinBet : null,
        activeButton: state.tickets.activeButton

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSelectedSystemTypeId, getTaxAndStake,
            createTicket, setBet, openPopup,
            openMessagePopup, setActiveButton
        },
        dispatch
    );
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketMenu)
