import React, {Component} from 'react';
import Country from '../../../../../helpers/country';
import BallItemWrapper from '../../../../centerColumn/balls/ballItems/BallItemWrapper';
import BallItem from '../../../../centerColumn/balls/ballItems/BallItem';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {handleBallClick, deleteCreatingTicket} from "../../../../../actions/ballActions";
import {getSystemTypes, deleteTicket} from "../../../../../actions/ticketActions";

class Ticket extends Component {
    state = {
        ball: [],
        ballsLength: -1,
        matches: window.matchMedia("(min-width: 812px)").matches
    }

    renderBalls() {
       // console.log(this.props.isState, 'this.props.this.props.isState')
        this.state.ball = this.props.matchedBalls ? this.props.matchedBalls : this.props.theSameBalls
        //  console.log( this.state.ball,' this.state.ball')
        const balls = [];
        const selectedBalls = this.props.open === true ? this.props.selectedBalls.sort((a, b) => a - b) : this.props.balls
        for (let i = 0; i < selectedBalls.length; i++) {
            // console.log(this.props.selectedBalls,"this.props.selectedBalls")
            if (i < selectedBalls.length) {
                balls
                    .push(
                        <BallItemWrapper key={i}>
                            <BallItem
                                onClick={(e) => this.handleBallClick(e, selectedBalls[i])}
                                ballId={selectedBalls[i]}
                                class={this.state.ball &&
                                this.state.ball.indexOf(selectedBalls[i]) !== -1 && this.props.isState !== 4 ? "matched" : " "}
                            />
                        </BallItemWrapper>)
            } //else balls.push( <BallItemWrapper key={i} />)
        }
        return balls;
        this.setState({ball: []})
    }

    clearTicket(e) {
        e.stopPropagation();
        this.props.deleteCreatingTicket()
    }

    componentDidMount() {
        this.setState({ball: []})
        this.props.getSystemTypes(this.props.ticketCompetitionId, this.props.selectedBalls.length)
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 812px)").addListener(handler);
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.ballsLength !== this.props.selectedBalls.length) {
            if (this.props.ticketCompetitionId !== null) {
                this.setState({ballsLength: this.props.selectedBalls.length});
                this.props.getSystemTypes(this.props.ticketCompetitionId, this.props.selectedBalls.length);
            }
        }
    }

    componentWillUnmount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 812px)").removeListener(handler);
    }

    /*handleBallClick(e, id) {
        e.stopPropagation();
        this.props.handleBallClick({id});
        //instead 1 should be passed this.props.competitionId when backend will support it
        this.props.getSystemTypes(1, this.props.selectedBalls.length);
        // return selectedBalls;
    }*/

    countryRender() {
        if (this.props.ticketRegionId !== null && this.props.open) {
            for (let item of this.props.competitionsList) {
                if (item.Id === this.props.ticketCompetitionId) {
                    return <Country
                        name={item.Name}
                        selectedCount={item.MaxSelectedNumbersCount}
                        numbersCount={item.NumbersCount}
                    />
                }
            }
        } else return <Country name={this.props.name}/>
    }

    deleteTicket = () => {
        this.props.deleteTicket(this.props.id);
    }

    render() {
        return (
            <div className="ticket-item-wrapper bg-closed">
                <div className="ticket-item">
                    <div>
                        <span className="ticket-number-wrapper">
                            {!this.props.open === true && this.props.deleteIcon ?
                                <span onClick={this.deleteTicket} className="delete-created-ticket"></span> : null}
                            <span className="ticket">Ticket N. {this.props.ticketNumber}</span>
                        </span>
                        {this.countryRender()}
                        <span className="country">SystemType : {this.props.systemType}</span>
                    </div>

                    {this.props.open === true ?
                        <div className="delete-wrapper" onClick={(e) => this.clearTicket(e)}>
                            <span className="delete">DELETE TICKET</span>
                        </div>
                        :
                        <div>
                            {this.props.totalWin !== 0 ?
                                <span className="win">Win: {this.props.totalWin}</span> : null}
                            {this.props.loose === 0 ?
                                <span className="loose">Lost: {this.props.totalWin}</span> : null}
                            {this.props.totalBet ? <span className="bet">Bet: {this.props.totalBet} AMD</span> : null}
                        </div>
                    }
                </div>

                {/*    <span className="info-create-tickets">ticket created, see on waiting tickets tab</span>*/}

                <div className="t-balls">
                    <div className="ball-items-container">
                        {this.props.balls ? this.renderBalls() : null}
                    </div>
                    {/* {!this.state.matches && this.props.open === true && <div className="percent-bar-wrapper">
                        <span>Chances to win</span>
                        <div className="rectangle">
                             <div className="percent-bar green-bar" style={{ width: `${this.props.probability}%`, transition: `all .4s ease-in-out ` }} />
                            <div className="percent-bar green-bar"
                                 style={{width: `${this.props.probability}%`, transition: `all .3s ease-out `}}></div>
                        </div>
                    </div>}*/}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let length = state.tickets.currentCreatingTicket.selectedBalls;
    return {
        selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
        competitionId: state.rounds.competition ? state.rounds.competition.Id : null,
        regionId: state.rounds.competition ? state.rounds.competition.RegionId : null,
        ticketCompetitionId: state.tickets.currentCreatingTicket.competitionId,
        ticketRegionId: state.tickets.currentCreatingTicket.regionId,
        selectedSystemId: state.tickets.currentCreatingTicket.selectedSystemId,
        competitionsList: state.competitions.competitionsAll,
        createdTickets: state.tickets.createdTickets.tickets,
        //state: state.tickets.createdTickets.state,
        theSameBalls: state.droppedNumber.theSameBalls,
        probability: length !== 0 ? state.tickets.currentCreatingTicket.probability : 0,

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            handleBallClick,
            getSystemTypes,
            deleteTicket,
            deleteCreatingTicket
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ticket)