import React from "react";
//import {connect} from 'react-redux';
//import {bindActionCreators} from "redux";

function ShowCurrency ({currencyId= "AMD"}) {
    return <span className={`currency 'C'`}>{currencyId}</span>
    //return <span className={`currency ${currencyId || 'C'}`} />;
}

// export default connect(
//     (state) => ({
//             currencyId: state.user.userData.currencyId,
//         }),
//     (dispatch) => bindActionCreators({}, dispatch)
// )(ShowCurrency);

export default ShowCurrency;