import React, {Component} from 'react';
import { connect } from "react-redux";
/**
 * class BallItem renders balls of lottery
 * function {this.renderColors} @returns ball colors
 * @params {this.props.ballId} @returns ball numbers
 */
class BallItem extends Component{
    render(){

        return(
                <div onClick={this.props.onClick} 
                     className={`ball-item ${this.props.class}`}
                     style={this.props.style}
                     >
                    <span>{this.props.ballId}</span>
                </div>
        )
    }
}
export default connect(
    null,
	null
)(BallItem)
