import React from 'react';

const  BallAnimation = React.forwardRef((props, ref) => {
    const { ballAnimation, animationText } = ref;
    return (
        <div ref={ballAnimation}>
            <div className="drawn-number">
                <span className="droppedNumber" ref={animationText}/>
            </div>
        </div>
    )
});

export default BallAnimation;
