import React from 'react';
import Loading from "../../../../components/loading";

const GamesList = props => {
    return(
        <div className="games-list-nav ">
            <div className="games-list-mini-view">
            {props.favorites === null || props.competitions === null || props.dates === null ? <Loading width="30" height="30"/> : null}  {/*||props.quickLotteries === null */}
                {/* competition-view element is parent of competition-title */}
                <div className="competition-view">
                    {/* competition-title should be on loop */}
                    {props.children}
                </div>
            </div>
        </div>
    )
}
export default GamesList;