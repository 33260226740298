import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import eng from "./languges/en";
import rus from "./languges/ru";
import arm from "./languges/arm";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: eng,
    ru: rus,
    arm: arm
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "eng",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
