import {
    GET_SYSTEM_TYPES, SET_SELECTED_SYSTEM_TYPE, GET_PROBABILITY,
    GET_TAX_AND_STAKE, GET_CREATED_TICKETS, SET_BET, ADD_CREATED_TICKET, DELETE_TICKET,
    GET_TICKETS_HISTORY, GET_BET_AMOUNT_RANGE, CLEAR_UPDATE_TICKETS, URL, GET_FINISHED_TICKETS,
    GAME_END, UPDATE_TICKETS, ADD_FINISHED_TICKETS, SET_TOTAL_TICKET, DELETE_TICKETS,
    DELETE_ROUND, ACTIVE_BUTTON, SET_ACTIVE_BUTTON, CHANGE_COMPETITIONID, GET_WINNER_TICKETS
} from "../constants"
import axios from "axios"
import {openMessagePopup} from './UI_actions'
import {call} from "./call"


export const setSelectedSystemTypeId = payload => ({
    type: SET_SELECTED_SYSTEM_TYPE,
    payload
})


export const getProbability = (id, systemId) => (dispatch) => {
    const data = {
        Controller: "Competition",
        Method: "GetProbability",
        RequestData: {"CompetitionId": id, "SystemTypeId": systemId}
    };
    call(data)()
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PROBABILITY,
                    payload: res.data.Object
                })
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}



let callSended = null;
export const getSystemTypes = (id, length) => (dispatch, getState) => {
    if (id !== null) {
        if (length !== 0) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            if (callSended !== null) {
                callSended.cancel('Operation canceled by the user.');
            }
            callSended = source;
            axios({
                method: "POST",
                url: URL,
                cancelToken: source.token,
                data: {
                    Controller: "Competition",
                    Method: "GetSystemTypes",
                    RequestData: JSON.stringify({"CompetitionId": id, "SelectedBallsLength": length})
                },
                config: {
                    headers: {"Content-Type": "application/x-www-form-urlencoded"}
                }
            })
                .then(res => {
                    callSended = null;
                    if (res.status === 200) {
                        if (res.data.Object.length !== 0) {
                            getProbability(id, res.data.Object[0].Id)(dispatch)
                        }
                        dispatch({
                            type: GET_SYSTEM_TYPES,
                            payload: res.data.Object
                        })
                    } else dispatch(openMessagePopup(res.message))
                })
                .catch(err => {
                    if (!(err.message === "Operation canceled by the user.")) {
                        dispatch(openMessagePopup(err.message))
                    }
                })
        } else {
            if (callSended !== null) {
                callSended.cancel('Operation canceled by the user.');
                callSended = null;
            }
            dispatch({
                type: GET_SYSTEM_TYPES,
                payload: []
            });
            dispatch({
                type: GET_PROBABILITY,
                payload: 0
            })
        }
    }
}

export const getTaxAndStake = (bet, id, systemTypeId) => (dispatch, getState) => {
    const data = {
        Controller: "Ticket",
        Method: "CalculateBetAmountDetails",
        RequestData: {
            BetAmount: bet,
            CompetitionId: id,
            SystemTypeId: systemTypeId
        }
    }
    call(data)(getState)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_TAX_AND_STAKE,
                    payload: res.data.Object
                })
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}


export const createTicket = (data) => (dispatch, getState) => {
    const requestData = {
        Controller: "Ticket",
        Method: "Create",
        RequestData: data
    };

    call(requestData)(getState)
        .then(res => {
            if (res.status === 200) {
                /**
                 * get all tickets API call is called when a ticket is created successfully
                 */
                dispatch({
                    type: ADD_CREATED_TICKET,
                    payload: res.data.Object
                })
                dispatch({
                    type: ACTIVE_BUTTON
                })

            }
        })
        .catch(err => {
            dispatch({
                type: ACTIVE_BUTTON
            })
            return dispatch(openMessagePopup(err.message))

        })
}

export const clearUpdateTickets = () => ({ type: CLEAR_UPDATE_TICKETS})


export const deleteTicket = (id) => (dispatch, getState) => {
    const data = {
        Controller: "Ticket",
        Method: "Delete",
        RequestData: {"Id": id}
    };
    call(data)(getState)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: DELETE_TICKET,
                    payload: id
                })
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}


export const setBet = (bet) => dispatch => {
    dispatch({
        type: SET_BET,
        payload: bet
    })
}


//start connect

let ws
let connect = (requestData, dispatch) => {
    ws = new WebSocket("wss://nlotto-api-stage.betcoapps.com/en/1/api/WebSocket/TicketsUpdateNotify")
    ws.onopen = () => {
        if (ws.readyState) {
            ws.send(JSON.stringify(requestData));
        }
    }

    ws.onmessage = (event) => {
        let res = JSON.parse(event.data);
        if (res.Status === "OK") {
            dispatch({
                type: UPDATE_TICKETS,
                payload: {
                    tickets: res.Data,
                }
            })
        }

        if (res.Status === "OK" && res.Data.State === 0) {
            dispatch(openMessagePopup('your tickets in this round roll backed'))
            dispatch({
                type: DELETE_TICKETS,
                payload: {
                    tickets: res.Data,
                    roundId: res.Data.RoundId,
                }
            })
            dispatch({
                type: DELETE_ROUND,
                payload: {
                    roundId: res.Data.RoundId
                }
            })
        }

        if (res.Status === "ERROR") {
            dispatch(openMessagePopup('No Authorisation Player'))
        }
    }

    ws.onclose = () => {
        console.log('WebSocket  is Close');
        setTimeout(() => {
            connect(requestData, dispatch);
        }, 6000)
    }

    ws.onerror = (err) => {
        console.error('Socket encountered error: ', err.message);
        ws.close();
    }
}
//end connect

export const getUpDateTickets = (token, partnerId, gameId) => (dispatch) => {
    const requestData = {
        PartnerId: partnerId,
        Token: token,
        GameId: gameId
    }
    if (!ws) {
        connect(requestData, dispatch);
    } else {
        if(ws.readyState){
            ws.send(JSON.stringify(requestData));
        }

    }
}

export const getAllWaitingTickets = () => (dispatch, getState) => {
    const data = {
        Controller: "Ticket",
        Method: "GetAllWaitingTickets",
    };

    return call(data)(getState)
        .then(res => {
            if (res && res.status === 200 && res.data.Object.length > 0) {
                dispatch({
                    type: GET_CREATED_TICKETS,
                    payload: {
                        tickets: res.data.Object,
                    }
                })
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

export const getFinishedTickets = (SkippingCount) => (dispatch, getState) => {
    const data = {
        Controller: "Ticket",
        Method: "GetFinishedTickets",
        RequestData: {
            "SkippingCount": SkippingCount ? SkippingCount : getState().tickets.ticketFinishedHistory.tickets.length,
            "ExpectedCount": 15
        },
    };
    return call(data)(getState)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_FINISHED_TICKETS,
                    payload: {
                        tickets: res.data.Object.Tickets,
                        totalCount: res.data.Object.TotalCount,
                        totalWin: res.data.Object.TotalWin,
                        totalBet: res.data.Object.TotalBet,
                    }
                })
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

export const getWinnerTickets = () => (dispatch) => {
    const data = {
        Controller: "Ticket",
        Method: "GetTopWinsTickets",
    }
    return call(data)()
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_WINNER_TICKETS,
                    payload: res.data.Object,

                })
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}


export const getTicketsHistory = (data, counter) => (dispatch, getState) => {
    const requestData = {
        Controller: "Ticket",
        Method: "GetFinishedTicketsByRoundId",
        RequestData: {RoundId: data}
    }
    return call(requestData)(getState)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_TICKETS_HISTORY,
                    payload: {
                        tickets: res.data.Object.Tickets,
                        totalBet: res.data.Object.TotalBet,
                        totalCount: res.data.Object.TotalCount,
                        totalWin: res.data.Object.TotalWin,
                        counter: counter
                    }
                })
            } else {
                if (res.message) {
                    dispatch(openMessagePopup(res.message))
                } else {
                    dispatch(openMessagePopup("No Result"))
                }
            }

        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

export const setGameEnd = (bool) => ({
    type: GAME_END,
    payload: bool
})

export const setTotalTicket = (total) => ({
    type: SET_TOTAL_TICKET,
    payload: total
})

export const addFinishedTicket = () => ({type: ADD_FINISHED_TICKETS});

export const getBetAmountRange = () => (dispatch, getState) => {
    const requestData = {
        Controller: "Ticket",
        Method: "GetBetAmountRange",
    };
    return call(requestData)(getState)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_BET_AMOUNT_RANGE,
                    payload: res.data.Object
                })
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

export const setActiveButton = () => ({ type: SET_ACTIVE_BUTTON })
//export const changeCompetition = (id) => ({ type: CHANGE_COMPETITIONID, payload: id })





