import React, {Component} from 'react';
import BallItem from './BallItem';
import BallItemWrapper from './BallItemWrapper';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import {handleBallClick} from "../../../../actions/ballActions";
import Loading from "../../../../components/loading";

class BallItems extends Component {
    state = {
        selectedBalls: []
    }


    addClass(ballId) {
        let className = "";
        let index = this.props.selectedBalls ? this.props.selectedBalls.indexOf(ballId) : -1;
        if (index !== -1 && this.props.ticketCompetitionId === this.props.competitionId) {
            className = "active";
        } else className = "";
        return className;
    }

    renderColors(ballId) {
        let colors = {
            border: "1.5px solid fff",
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            color: "#000",
            boxShadow: "",
        };
        if (ballId === 77 || ballId === 28 || ballId === 15) colors.border = "1.5px solid #326CA1"
        else if (ballId === 75 || ballId === 31 || ballId === 54) colors.border = "1.5px solid #B73C3C"
        else return colors;
        return colors;
    }

    handleClick(e, id, competitionId, regionId) {
        this.props.handleBallClick({id, competitionId, regionId})
    }

    renderBalls() {
        const balls = [];
        for (let i = 1; i <= 91; i++) {
            if (i <= this.props.lotteriesCount) {
                balls.push(
                    <BallItemWrapper key={i}>
                        <BallItem
                            ballId={i}
                            onClick={(e) => this.handleClick(e, i, this.props.competitionId, this.props.regionId)}
                            selectedBalls={this.props.selectedBalls}
                            competitionId={this.props.competitionId}
                            ticketCompetitionId={this.props.ticketCompetitionId}
                            style={this.renderColors(i)}
                            class={this.addClass(i)}
                        />
                    </BallItemWrapper>)
            } else balls.push(<BallItemWrapper key={i}/>)
        }
        return balls;
    }

    render() {
        return (
            <div className={`${(this.props.statusRound.roundId === this.props.selectedRoundId) ||
            (this.props.noActiveRound.roundState === 1 && this.props.selectedRoundId === this.props.noActiveRound.roundId) ?
                'ball-items-container no-bet' : 'ball-items-container'} `}>
                {this.props.lotteriesCount !== 0 ? null : <Loading width="60" height="60"/>}
                {this.renderBalls()}
            </div>
        )
    }
}

export default connect(
    state => ({
    lotteriesCount: state.rounds.competition ? state.rounds.competition.NumbersCount : 0,
    selectedRoundId: state.rounds.selectedRoundId,
    statusRound: state.rounds.statusRound,
    competitionId: state.rounds.competition ? state.rounds.competition.Id : null,
    regionId: state.rounds.competition ? state.rounds.competition.RegionId : null,
    selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
    ticketCompetitionId: state.tickets.currentCreatingTicket.competitionId,
    noActiveRound: state.rounds.noActiveRound
}),
    dispatch => bindActionCreators({
        handleBallClick
    }, dispatch)
)(BallItems)