import { GET_FAVORITES, REMOVE_FAVORITE } from "../constants";

const ACTION_HANDLERS = {
    [GET_FAVORITES]: (state, action) => {
        let favoritesList = action.payload.length === 0 ? [] : action.payload
        favoritesList = favoritesList.filter((elm) => elm !== action.payload)
        return {
            ...state,
            favoritesList 
        }
    },
    [REMOVE_FAVORITE]: (state, action) => {
        const favorites = { ...state.favoritesList };
        for (let i in favorites) {
            if (favorites[i].Id === action.payload) {
                delete favorites[i];
            }
        }
        return {
            ...state,
            favoritesList: Object.values(favorites).length === 0 ? [] : favorites,
        };
    }
}

const initialState = {
    favoritesList: []
}

const favoriteReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default favoriteReducer;