import React, {useState, useEffect, Fragment} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {getRound, setSelectIndex} from "../../actions/roundActions"


const RoundMobile = (props) => {
    const [round, setRound] = useState([])
    const [coeffsCent, setCoeffsCent] = useState([])

    useEffect(() => {
        if (props.roundsList.length > 0 && props.competitionsAll !== null && props.competitionId) {
            let competition = props.competitionsAll
            setCoeffsCent(competition.filter((elem) => elem.Id === props.competitionId))
            setRound([props.roundsList[props.currentIndex]])
            props.getRound(props.roundsList[props.currentIndex])
        }
    }, [props.roundsList, props.competitionId,])

    let activeTime = (e, round, time, ind) => {
        props.setSelectIndex(ind)
        setRound(props.roundsList.filter((item) => item.StartTime === time))
        props.getRound(round)
    }

    return (
        <Fragment>
            <div className="mobile-rounds">
                {props.roundsList.map((elm, ind) => {
                    return (
                        <span className={`rounds-elements ${ind === props.currentIndex ? "active" : " "}`}
                              key={ind} onClick={(e) => activeTime(e, elm, elm.StartTime, ind)}>
                                            {elm.StartTime}
                                        </span>
                    )
                })
                }
            </div>
            <div className="rounds-item">
                {round.map((item, index) => {
                    return (
                        <div className="all-rounds-list" key={index}>
                            <div className="rounds-item-elements">
                                <span>RoundId</span>
                                <span>{item.RoundNumber}</span>
                            </div>
                            <div className="rounds-item-elements">
                                <span>Time</span>
                                <span>{item.StartTime}</span>
                            </div>
                            {coeffsCent.length && coeffsCent[0].Coefficients.map((elem, key) => {
                                return (
                                    <div className="rounds-item-elements" key={key}>
                                        <span>{key + 1}</span>
                                        <span>{elem}</span>
                                    </div>)
                            })
                            }
                        </div>
                    )
                })
                }
            </div>
        </Fragment>
    )
}

export default connect(
    state => ({
        roundsList: state.rounds.allRoundsList,
        competitionsAll: state.competitions.competitionsAll,
        competitionId: state.rounds.competitionId,
        currentIndex: state.rounds.currentIndex
    }),
    dispatch => bindActionCreators(
        { getRound, setSelectIndex }, dispatch
    )
)(RoundMobile)


