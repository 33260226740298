import React from "react";
import RightTopBox from './rightTopBox/index';
import RightMenuWrapper from './rightMenuBox/index';
import {connect} from "react-redux";
const RightColumn =(props) => {
        return(
            <div className="r-column-wrapper">
                {props.token && <RightTopBox/>}
                <RightMenuWrapper/>
            </div>
        )
    }

export default connect(state => ({
    token:state.user.token
}), null) (RightColumn);