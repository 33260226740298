import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import TicketWrapper from '../ticketWrapper/index';
import {
    getAllWaitingTickets,
    deleteTicket,
    getFinishedTickets,
    getUpDateTickets,
} from "../../../../actions/ticketActions";
import {withTranslation} from 'react-i18next';


const CreatingTickets = (props) => {

    let checkTicketData = () => {
        if (props.competitionId === null && props.createdTickets && props.ticketFinishedHistory) {
            if (props.createdTickets.length === 0 && props.ticketFinishedHistory.length === 0) {
                debugger
                return true
            }
        }
        return false
    }

    /* scrolling = (e) => {
         if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && this.props.ticketFinishedHistory.length < this.props.totalCount) {
             this.setState({isLoading: true});
             this.props.getFinishedTickets()
                 .then(() => {
                     this.setState({isLoading: false})
                 })
         }
     }*/


    // const { t } = this.props;
    return (
        // <h1 style={{color:"white"}}>creating tickets mobile </h1>
        <Fragment>
            {props.selectedBalls.length > 0 && <div className="r-full-menu-wrapper-box creating-t-mobile">
                {checkTicketData() ?
                    <div className="fav-numbers-info-box">
                        <p>
                            choose your favorite
                            <br/>
                            numbers from the left side
                        </p>
                    </div>
                    :
                    <div className="tickets-container">
                        {props.competitionId !== null ?
                            <TicketWrapper balls={props.selectedBalls} open={true}/>
                            : null
                        }
                    </div>
                }
            </div>}
        </Fragment>
    )

}

export default compose(
    withTranslation("translation"),
    connect(
        (state) => ({
            competitionId: state.tickets.currentCreatingTicket.competitionId,
            createdTickets: state.tickets.createdTickets.tickets,
            ticketFinishedHistory: state.tickets.ticketFinishedHistory.tickets,
            selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
            // totalCount: state.tickets.createdTickets.totalCount,
            token: state.user.token,
            // theSameBalls: state.droppedNumber.theSameBalls ? state.droppedNumber.theSameBalls : null,
            roundId: state.rounds.statusRound.roundId,
            gameEnd: state.tickets.gameEnd,
            round: state.rounds.round
        }),
        dispatch => bindActionCreators({
            getAllWaitingTickets,
            deleteTicket,
            getFinishedTickets,
            getUpDateTickets
        }, dispatch),
    ))(CreatingTickets);


