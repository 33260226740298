import React, { Component } from "react";
import { connect } from "react-redux";
import { openPopup } from "../actions/UI_actions";
import { bindActionCreators } from "redux";
import Button from "../components/Button";
import HotNumberItem from "../components/centerColumn/balls/hotNumbers/HotNumberItem";
import Loading from "../components/loading";

/**
 * class TicketModal
 */
class HotNumbersModal extends Component {
   state = {
      hig: [],
      mid: [],
      low: []
   };

   componentDidUpdate(prevProps) {
      if (
         prevProps.hotNumber !== this.props.hotNumber &&
         this.props.hotNumber.length > 0
      ) {
         const length = Math.round(this.props.hotNumber.length / 3);
         this.setState({
            hig: this.state.hig.concat(this.props.hotNumber.slice(0, length)),
            mid: this.state.mid.concat(
               this.props.hotNumber.slice(length, length * 2)
            ),
            low: this.state.low.concat(
               this.props.hotNumber.slice(
                  length * 2,
                  this.props.hotNumber.length
               )
            )
         });
      }
   }

   render() {
      return (
         <div className="popups-container">
            {this.props.hotNumber.length > 0 ? (
               <div className="popup-wrapper">
                  <div className="modal-wrapper ticket-m">
                     <div
                        className="closed-btn"
                        onClick={() => this.props.openPopup(null)}
                     />
                     <div className="modal-header-w">
                        <h3>Most drawn numbers</h3>
                     </div>
                     <div className="modal-content-wrapper">
                        <p>
                           Numbers ordered by the frequecy of deopouts over the
                           last 100 rounds
                        </p>

                        <div className="hotNumbers-items-wrapper">
                           <ul>
                              <li>High</li>
                              <li>
                                 <HotNumberItem data={this.state.hig} />
                              </li>
                           </ul>
                           <ul>
                              <li>Mid</li>
                              <li>
                                 <HotNumberItem data={this.state.mid} />
                              </li>
                           </ul>
                           <ul>
                              <li>Low</li>
                              <li>
                                 <HotNumberItem data={this.state.low} />
                              </li>
                           </ul>
                        </div>
                        <Button
                           onClick={() => this.props.openPopup(null)}
                           name="got it"
                        />
                     </div>
                  </div>
               </div>
            ) : (
               <Loading width="40" height="40" />
            )}
         </div>
      );
   }
}

export default connect(
   state => ({
      hotNumber: state.droppedNumber.hotNumber
   }),
   dispatch => bindActionCreators({ openPopup }, dispatch)
)(HotNumbersModal);
