import React from 'react';

const Title = props =>  {
        return(

                <div onClick={props.onClick} className={`sports-item ${props.type}`}>
                    <p>{props.name}</p>
                    {props.type !== "favorites" ?
                        <div className="sport-games-count">
                            <span>{props.count}</span>
                        </div> : null}
                    {/*<div className="mobile-show-count"><span>222</span></div>*/}
                    <div className="arrow-view"></div>
                </div>


        )
}

export default Title;