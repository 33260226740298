
let eng = {
    translation: {
        "please-bets": "please place your bets",
        "numbers-rounds": "Numbers ordered by the frequency of dropouts over the last 100 rounds",
        "tickets": "tickets",
        "result": "result",
        "waiting-tickets": "Waiting Tickets",
        "finished-tickets": "Finished Tickets",
        "random-select": "random select",
        "clear": "Clear",
        "hot-numbers": "Hot numbers",
    }
};

export default eng;