import React from 'react';

const RoundTab = ({getFilteredRounds, date, day, className, index}) => {
    return(
        <li onClick={getFilteredRounds} className={className}>
        {index === 0 ? <div>All</div> : <div>
            <span>{date.slice(0,5)}</span>
            <span>{day}</span>
        </div>}
    </li>
    )
};

export default RoundTab;