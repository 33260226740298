import React from 'react';

const FullRegionInfo = props => {
    return(
        <div className="region-item-row">
            <div className="region-items-box">
                <div className="full-region-info">
                    {props.children}
                 </div>
             </div>
        </div>
    )
}

export default FullRegionInfo;