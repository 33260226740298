let arm = {
    translation: {
        "please-bets": "խնդրում ենք տեղադրել ձեր խաղադրույքները",
        "numbers-rounds": "Վերջին 100 ռաունդների ընթացքում թողումների հաճախականության համաձայն պատվիրված թվեր",
        "tickets": "տոմսեր",
         "result": "արդյունք",
        "waiting-tickets": "Սպասման տոմսեր",
        "finished-tickets": "Ավարտված տոմսեր",
        "random-select": "պատահական ընտրություն",
        "clear": "Մաքրել",
        "hot-numbers": "Թեժ համարներ լոտոյում"
    }
}
export default arm;