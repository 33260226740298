import {
    GET_COMPETITIONS,
    GET_COMPETITION_ARR,
    GET_COEFFICENTS,
} from '../constants';
import { openMessagePopup } from './UI_actions';
import { call } from "./call";

/**
 *
 * @param {*} regionId number
 * @param dispatch function
 */
export const getCompetitions = (regionId) => dispatch => {
    let checker = true;
    
    if (regionId.CompetitionId) {
        checker = false;
        regionId = regionId.RegionId
    }
    const data = {
        Controller: "Competition",
        Method: "GetAll",
        RequestData: {"RegionId": regionId}
    }

    call(data)()
        .then(res => {
            //handle success
            if (res.status === 200) {
                if (checker) {
                    dispatch({
                        type: GET_COMPETITIONS,
                        competitions: res.data.Object,
                        regionId
                    })
                } else {
                    dispatch({
                        type: GET_COMPETITION_ARR,
                        competition: res.data.Object[0]
                    })
                }
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
};


export const getAllCopmetitions = () => (dispatch, getState) => {
    let requestDate = {
        Controller : "Competition",
        Method: "GetCompetitions"
    }

    call(requestDate)(getState)
        .then(res => {
            if(res.status === 200){
                dispatch({
                    type: GET_COMPETITION_ARR,
                    payload: res.data.Object,
                })
            }
            if (res.data.Object[0].Coefficients.length > 0) {
                dispatch({
                    type: GET_COEFFICENTS,
                    payload: res.data.Object[0].Coefficients
                })
            }

        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}