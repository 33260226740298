import axios from "axios";
import { URL } from "../constants";
export const call = data => getState => {
 
    const requestData = {
        Controller: data.Controller,
        Method: data.Method,
        RequestData: JSON.stringify(data.RequestData),
        TimeZone: data.TimeZone,
        PlayerId: data.PlayerId,
        Token: data.Token
    }

    if (getState !== undefined) {
        requestData.Token = getState().user.token;
        requestData.PartnerId = getState().user.partnerId
        requestData.GameId = getState().user.gameId

    }
    return axios({
        method: "POST",
        url: URL,
        data: requestData,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}