import React from 'react';

const Titles = ({coef}) => {
    function renderCoefs(){
        let coefs = [];
        for(let i = 1; i <= coef; ++i){
            coefs.push(i);
        }
        return coefs;
    }
    return (
        <ul className="info-t-title">
            <li>Round ID</li>
            <li>Time</li>
            {renderCoefs().map((item, index)=>{
                return <li key={index}>{item}</li>
            })}
        </ul>
    );
}

export default Titles;