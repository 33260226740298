import React, {Component} from "react"
import RoundTab from './RoundTab';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFilteredRounds, getRounds} from '../../../actions/roundActions'


class RoundTabs extends Component {
    state = {
        dates: [],
        clickedAll: false,
        selectedCompetitionID: null,
        // activeClass: false
    }

    componentDidMount() {
        this.getRoundStartDate()
    }

    getRoundStartDate() {
        let roundDates = [];
        const days = {
            0: "Sunday",
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday"
        } 

        function formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            return [day, month, year].join("/")
        }

        for (let i = -1; i < 9; i++) {
            let today = new Date()
            today.setDate(today.getDate() + i)
            roundDates.push({date: formatDate(today), day: days[today.getDay()]})
        }
        this.setState({dates: roundDates})
    }

    addActiveClass = (date) => {
        let newDate;
        if (date) {
            let x = date.replaceAll('/', '-');
            for (let i = 0; i < x.length; i++) {
                newDate = x[8] + x[7] + x[6] + x[9] + x[2] + x[3] + x[4] + x[5] + x[0] + x[1]
            }
        }
        let className = "";
        if (newDate === this.props.selectedDate && !this.state.clickedAll) className += "active";
        (!this.props.rounds && !this.props.competition) ? className += "disabled" : className += "";

        return className;
    }

    /*  addClass = (date) => {
          debugger
          let active = ''
          if(date === this.props.selectedDate ) active += 'active'
          return active;
      }*/

    getFilteredRounds = (date, index) => {
        if (index === 0) {
            let item = this.props.competition
            let skippingCount = 0
            this.props.getRounds(item, skippingCount);
            this.setState({clickedAll: true})
        } else {
            this.props.getFilteredRounds(date, 0)
            this.setState({clickedAll: false})
        }

    };

    componentDidUpdate() {

        if (this.state.selectedCompetitionID === null) {

            if (this.props.competition) {
                this.setState({selectedCompetitionID: this.props.competition.Id})
            }
        }
        if ((this.state.selectedCompetitionID !== null && this.props.competition !== undefined && this.props.competition.Id !== this.state.selectedCompetitionID)) {
            console.log(this.props.competition.Id,'props.id inn')
            this.setState({clickedAll: true, selectedCompetitionID: this.props.competition.Id})
        }
    }

    render() {
        return (
               <ul  className='date-row-filter'>
                    {this.state.dates.map((item, index) => {
                        return (
                            <RoundTab key={index}
                                      index={index}
                                      getFilteredRounds={() => this.getFilteredRounds(item.date, index)}
                                      date={item.date}
                                      day={item.day}
                                      className={this.addActiveClass(item.date)}
                                      addClass={() => this.addClass(item.date, index)}
                            />
                        )
                    })}
                </ul>
        )
    }
}

export default connect(
    state => ({
        competition: state.rounds.competition,
        selectedDate: state.rounds.selectedDate,
        rounds: state.rounds.roundsList.length !== 0 ? true : false,
    }),
    dispatch => bindActionCreators({getFilteredRounds, getRounds}, dispatch)
)(RoundTabs);