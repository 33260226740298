import {
    GET_FAVORITES,
} from "../constants";
import {openMessagePopup} from './UI_actions';
import {call} from './call';

export const getFavorite = () => (dispatch, getState) => {
    let requestDate = {
        Controller: "Favorite",
        Method: "GetAll"
    }
    call(requestDate)(getState)
        .then(res => {
            if (res.status === 200 && res.data.Object.length > 0) {
                dispatch({
                    type: GET_FAVORITES,
                    payload: res.data.Object
                })
            }
        }).catch(err => dispatch(openMessagePopup(err.message)))
};



