import {SET_LANGUAGES, SET_TOKEN, SET_PARTNERID, SET_GAMEID} from "../constants";

const ACTION_HANDLERS = {
    [SET_TOKEN] : (state, action) => {
        return {
            ...state,
            token: action.data ? action.data : null
        }
    },
    [SET_LANGUAGES] : (state, action) => {
        return {
            ...state,
            lang: action.lang ? action.lang : null
        }
    },
    [SET_PARTNERID] : (state, action) => {
        return {
            ...state,
            partnerId: action.partnerId ? action.partnerId : null
        }
    },
    [SET_GAMEID] : (state, action) => {
        return {
            ...state,
            gameId: action.gameId ? action.gameId : null
        }
    },
}

const initialState = {
    lang: null,
    token : null,
    userData: {},
    partnerId: null,
    gameId: null
}

const userReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default userReducer;