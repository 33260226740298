import React, {Component, Fragment} from 'react';

import LotteryTabs from "../../tabs/LotteryTabs";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {openPopup, openMobileComponent} from '../../../actions/UI_actions';
import {getPlayerRounds} from "../../../actions/roundActions";
import {setTotalTicket} from "../../../actions/ticketActions";
import {withTranslation} from "react-i18next";
import isMobile from "../../../helpers/mobile"

class RightTopBox extends Component {
    state = {
        components: {
            home: "Game",
            result: "Results",
            waitingTickets: "Waiting Tickets",
            winnerTickets: "Winner Tickets",
            finishedTickets: "Finished Tickets"
        }
    }


    handleClick = () => {
        this.props.openPopup('getPlayerRoundsResult')
        this.props.getPlayerRounds(0);
    };

    displayComponent = (component) => {
        if (component === "result") {
            this.props.getPlayerRounds(0);
        }
        this.props.openMobileComponent(component)
    }

    render() {

        const {t} = this.props

        if (this.props.round && this.props.createdTickets && this.props.createdTickets.length > 0) {
            const allTickets = this.props.createdTickets
            const result = allTickets.filter(elem => elem.RoundId === this.props.round.Id)
            let total = result.length
            this.props.setTotalTicket(total)
        }
        return (
             <Fragment>
                {!(isMobile() || this.props.mobileSize) ?
                    <div className="desktop-header r-top-box">
                        <div className="lottery-tab-wrapper">
                            <LotteryTabs count={this.props.totalTicket} ticketsMaxCount={this.props.ticketsMaxCount}/>
                            <div onClick={this.handleClick}
                                 className={`r-tab ${!this.props.competitionId ? 'avoid-clicks' : null}`}>
                                <div className="r-text">
                                    <span>{t("result")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="mobile-header r-top-box">
                        <div className="lottery-tab-wrapper">
                            {/* <LotteryTabs  count={this.props.totalTicket}/> */}

                            {Object.keys(this.state.components).map((item, index) => {
                                return (
                                    <div key={index} onClick={() => this.displayComponent(item)}
                                         className={`r-tab ${this.props.component === item ? "active" : ""} ${!this.props.competitionId ? 'avoid-click' : ""}`}>
                                        <div className="r-text">
                                            <span>{this.state.components[item]}</span>
                                            {/* <span>{ t("result") }</span> */}
                                        </div>
                                    </div>
                                )
                            })
                            }

                            {/* <div onClick={() => this.displayHome("home")}
                             className={`r-tab ${active} ${!this.props.competitionId ? 'avoid-clicks' : ""}`}>
                            <div className="r-text">
                                <span>Home</span>
                            </div>
                        </div>
                        <div onClick={() => this.displayRoundsResult('getPlayerRoundsResultMobile')}
                             className={`r-tab ${active} ${!this.props.competitionId ? 'avoid-clicks' : ""}`}>
                            <div className="r-text">
                                <span>{ t("result") }</span>
                            </div>
                        </div>
                        <div onClick={() => this.displayWaitingTickets('waitingTickets')}
                             className={`r-tab ${active} ${!this.props.competitionId ? 'avoid-clicks' : ""}`}>
                            <div className="r-text">
                                <span>Waiting Tickets</span>
                            </div>
                        </div>  */}

                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        counter: state.rounds.roundsHistory.counter,
        createdTickets: state.tickets.createdTickets.tickets,
        competitionId: state.rounds.competition ? state.rounds.competition.Id : null,
        token: state.user.token,
        round: state.rounds.round,
        totalTicket: state.tickets.totalTicket,
        ticketsMaxCount: state.competitions.competitionsAll ? state.competitions.competitionsAll[0].TicketsMaxCount : null,
        mobileSize: state.mobile.mobileSize,
        component: state.UI.component,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            openPopup,
            getPlayerRounds,
            setTotalTicket,
            openMobileComponent
        },
        dispatch
    );
};
export default compose(
    withTranslation("translation"),
    connect(mapStateToProps, mapDispatchToProps))(RightTopBox);