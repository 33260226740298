import {
    GET_ROUNDS, GET_ROUND, SET_ID,
    GET_FILTERED_ROUNDS, GET_COMPETITION,
    GET_PLAYER_ROUNDS, GET_ROUNDS_COUNT,
    STATUS_ROUND, DELETE_ROUND, GET_DROPPED_NUMBER,
    GET_ROUND_STATE, GET_CURENT_REGION_ID, CHANGE_COMPETITIONID, GET_COUNT, SET_INDEX
} from "../constants";
import {getCompetitions} from './competitionActions'
import {openMessagePopup} from './UI_actions';
import {call} from "./call";

/**
 *
 * @param {*} competitionId number
 * @param dispatch function
 */

export const getRounds = (item, skippingCount, expectedCount) => (dispatch, getState) => {
    if (item === undefined) {
        item = getState().rounds.competition
    }
    let id = item.CompetitionId ? item.CompetitionId : item.Id;
    if (id !== getState().rounds.competitionId) {
        skippingCount = 0;
    }
    if (item !== undefined) {
        let date = new Date().getTimezoneOffset();
        let timeZone = -(date);
        const data = {
            Controller: "Round",
            Method: "GetAll",
            TimeZone: timeZone,
            RequestData: {
                CompetitionId: id,
                SkippingCount: skippingCount === 0 ? 0 : getState().rounds.allRoundsList.length,
                ExpectedCount: expectedCount ? expectedCount : 15,
            }
        };

        call(data)()
            .then(res => {
                //handle success
                if (res.status === 200) {
                 dispatch({
                        type: GET_ROUNDS,
                        receivedRounds: res.data.Object,
                        competitionId: id,

                    });
                    if (res.data.Object.Data[0].Numbers.length > 0 && getState().droppedNumber.droppedNumbers.number.length === 0) {
                        dispatch({
                            type: GET_DROPPED_NUMBER,
                            payload: {
                                droppedNumbers:  res.data.Object.Data[0].Numbers,
                                droppedStartTime:  res.data.Object.Data[0].NumbersDroppedDate,
                                roundId:  res.data.Object.Data[0].Id
                            }
                        })
                    }
                    if (res.data.Object.Data[0].State === 1) {
                        dispatch({
                            type: GET_ROUND_STATE,
                            payload: {
                                roundState: res.data.Object.Data[0].State,
                                roundId: res.data.Object.Data[0].Id
                            }
                        })
                    }
                    if (getState().tickets.currentCreatingTicket.competitionId !== null) {
                        dispatch({
                            type: CHANGE_COMPETITIONID,
                        })
                    }

                    if (item.CompetitionId) {
                        if (item.CompetitionId !== getState().rounds.competitionId) {
                            getCompetitions(item)(dispatch)
                        }

                    } else {
                        dispatch({
                            type: GET_COMPETITION,
                            competition: item
                        });
                    }
                }
            })
            .catch(err => {
                dispatch(openMessagePopup(err.message))
            });
    }
};

// WS part start
let ws;
let connect = (id, dispatch) => {
    ws = new WebSocket('wss://nlotto-api-stage.betcoapps.com/en/1/api/WebSocket/RoundsStateChanges');
    ws.onopen = () => {
        ws.send(id);
    };

    ws.onmessage = (event) => {
        let res = JSON.parse(event.data);
        if (res.Status === "OK" && res.Data.RoundState === 1) {
            dispatch({
                type: STATUS_ROUND,
                payload: {
                    roundId: res.Data.RoundId,
                    roundState: res.Data.RoundState,
                }
            })
        }

        if (res.Status === "OK" && res.Data.RoundState === 0) {
            dispatch(openMessagePopup('round crashed, your ticket will roll back'))
        }

        if (res.Status === "ERROR") {
            dispatch(openMessagePopup('Time out error'))
        }
    };

    ws.onclose = () => {
        console.log('WebSocket  is Close');
        setTimeout(() => {
            connect(id, dispatch);
        }, 5000)
    };

    ws.onerror = (err) => {
        // console.error('Socket encountered error: ', err.message);
        ws.close();
    }
};

// WS part end

export const getStatusRound = (item) => (dispatch, getSate) => {
    let id = item.CompetitionId ? item.CompetitionId : item.Id;

    if (id !== undefined) {
        if (!ws) {
            connect(id, dispatch);
        } else {
            if (ws.readyState) {
                ws.send(id);
            }
        }
    }
};

export const getRound = round => ({type: GET_ROUND, round});
export const setSelectIndex = (index) => ({type: SET_INDEX, payload: index});

export const getFilteredRounds = (info, skippingCount) => (dispatch, getState) => {
    let newDate;
    if (info) {
        let x = info.replaceAll('/', '-');
        for (let i = 0; i < x.length; i++) {
            newDate = x[8] + x[7] + x[6] + x[9] + x[2] + x[3] + x[4] + x[5] + x[0] + x[1]
        }
    } else {
        newDate = getState().rounds.selectedDate
    }

    let date = new Date().getTimezoneOffset();
    let timeZone = -(date);

    const data = {
        Controller: "Round",
        Method: "GetAll",
        TimeZone: timeZone,
        RequestData: {
            CompetitionId: getState().rounds.competitionId,
            SkippingCount: skippingCount === 0 ? 0 : getState().rounds.allRoundsList.length,
            ExpectedCount: 15,
            Date: newDate
        }
    };

    call(data)()
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_FILTERED_ROUNDS,
                    roundDate: res.data.Object.Data,
                    selectedDate: newDate,
                    totalCount: res.data.Object.TotalCount
                })
            } else {
                dispatch(openMessagePopup(res.message))
            }
        })
        .catch(err => {
            dispatch(openMessagePopup(err.message))
        });


};

export const getPlayerRounds = (skippingCount) => (dispatch, getState) => {
    let date = new Date().getTimezoneOffset();
    let timeZone = -(date);
    const requestData = {
        Controller: "Round",
        Method: "GetRoundHistory",
        TimeZone: timeZone,
        RequestData: {
            CompetitionId: getState().rounds.competitionId,
            skippingCount: skippingCount === 0 ? 0 : getState().rounds.roundsHistory.allList.length,
            ExpectedCount: 15
        },
    };

    call(requestData)(getState)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PLAYER_ROUNDS,
                    payload: {
                        rounds: res.data.Object.Data,
                        totalCount: res.data.Object.TotalCount,
                    }
                })
            } else {
                if (res.message) {
                    dispatch(openMessagePopup(res.message))
                } else {
                    dispatch(openMessagePopup("No Result"))
                }
            }
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
};

export const getCountRounds = (count) => {
    return {
        type: GET_ROUNDS_COUNT,
        counter: count
    }

};


export const getCurrentRegionId = (id) => ({
    type: GET_CURENT_REGION_ID,
    RegionId: id
});

export const deleteRound = () => ({
    type: DELETE_ROUND,
});

export const getCount = (data) => ({type: GET_COUNT, payload: data})
