import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setAnimationEnd} from "../../../../actions/numberActions";

class TimeAnimation extends Component {
    constructor(props) {
        super(props);
        this.state = {count: 5};
        this.time = setInterval(() => this.clock(), 1000);
    }

    clock() {
        const current = this.state.count;
        if (current === 0) {
            this.clear();
            this.props.setAnimationEnd(true);
        } else {
            this.setState({count: current - 1});
        }
    }

    clear() {
        clearInterval(this.time);
    }

    componentWillUnmount() {
        this.clear()
    }

    render() {
        return (
            <div className="remaining-time">
                <div className="spinner-circular one"></div>
                <div className="spinner-circular two"></div>
                <div className="spinner-circular">
                    <span>{this.state.count}</span>
                </div>
                {/* <svg height="0" width="0">
                <defs>
                    <path id="path-1" opacity="1" fillRule="evenodd" d="M75.40926021895712,42.409260218956916 C75.40926021895712,60.62245602725892 60.62245602725912,75.40926021895692 42.409260218957115,75.40926021895692 C24.19606441065512,75.40926021895692 9.409260218957122,60.62245602725892 9.409260218957115,42.40926021895692 C9.409260218957115,24.19606441065492 24.196064410655108,9.409260218956923 42.40926021895711,9.409260218956916 C60.62245602725912,9.409260218956916 75.40926021895712,24.196064410654916 75.40926021895712,42.409260218956916Z"/>
                </defs>
                <g opacity="0.3">
                    <use xlinkHref="#path-1" x="-7" y="-7" fillOpacity="0" stroke="#7B8191" strokeOpacity="1" strokeWidth="4"/>
                </g>
                <defs>
                    <path id="path-1" opacity="1" fillRule="evenodd" d="M75.40926021895712,42.409260218956916 C75.40926021895712,60.62245602725892 60.62245602725912,75.40926021895692 42.409260218957115,75.40926021895692 C24.19606441065512,75.40926021895692 9.409260218957122,60.62245602725892 9.409260218957115,42.40926021895692 C9.409260218957115,24.19606441065492 24.196064410655108,9.409260218956923 42.40926021895711,9.409260218956916 "/>
                    <linearGradient id="linearGradient-2" x1="0.5" y1="0.5" x2="0.21" y2="0.26">
                    <stop offset="0" stopColor="rgb(255,255,255)" stopOpacity="0"/>
                    <stop offset="1" stopColor="rgb(255,255,255)" stopOpacity="1"/>
                    </linearGradient>
                </defs>
                <g opacity="1">
                    <use xlinkHref="#path-1" x="-7" y="-7" fillOpacity="0" stroke="url(#linearGradient-2)" strokeOpacity="1" strokeWidth="4"/>
                </g>
               
            </svg> */}
            </div>

        )
    }
}

export default connect (
    state => ({}),
        dispatch => bindActionCreators({setAnimationEnd}, dispatch)
  ) (TimeAnimation)
