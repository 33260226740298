import React from 'react';
import Favorites from './favorites/index';
import Lotteries from './lotteries/lotteries';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


const LeftMenuWrapper = (props) => {
    return (
        <>
            <div className="l-full-menu-wrapper-box">
                <div className="l-full-menu-wrapper">
                    {props.token && <Favorites/>}
                    <Lotteries filterBySelect={props.filterBySelect}/>
                </div>
            </div>
        </>
    );

}


export default connect(state => ({
        filterBySelect: state.regions.filterBySelect,
        token: state.user.token
    }),
    dispatch => bindActionCreators({}, dispatch))(LeftMenuWrapper);
