import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import Ticket from './ticket/index';
import TicketMenu from './ticketMenu/index';
import {connect} from "react-redux";

const TicketWrapper = (props) => {
    const [isState, setIsState] = useState(4)
    return (
        <div className="creating-ticket-wrapper">
            {props.token && <Collapsible open={props.open}
                                         trigger={
                                             <Ticket open={props.open} balls={props.balls} ticketNumber={props.ticketNumber}
                                                     isState={isState}/>
                                         }>
                <TicketMenu/>
            </Collapsible>}
        </div>
    );

}
export default connect(state => ({
        token: state.user.token,
    }), null,
)(TicketWrapper);