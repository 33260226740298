import React from 'react';

const Flag = ({countryCode}) => (
    <div className="flag">
         <span className={`flag-icon-c flag-icon-${
                     countryCode ? countryCode.toLowerCase() : null
                  }`}
        ></span>
    </div>
)

export default Flag;