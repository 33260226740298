import { GET_COMPETITION_ARR, GET_COMPETITIONS } from '../constants'

const ACTION_HANDLERS = {
    [GET_COMPETITIONS]: (state, action) => {
        return {
            ...state,
            competitionsAll: action.payload,
        }
    },
    [GET_COMPETITION_ARR]: (state, action) => {
        return {
            ...state,
            competitionsAll: action.payload
        }

    }
}

const initialState = {
    competitionsAll: null,
    competitionsList: null,
    regionId: null,
}
const competitionReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}

export default competitionReducer