import { HANDLE_BALL_CLICK, GET_RANDOM_SELECTED_MAX_VALUE, DELETE_CREATING_TICKET, CLEAR_BALLS } from "../constants";

export const handleBallClick = payload => {
    return {
        type: HANDLE_BALL_CLICK,
        payload
    }
};

export const deleteCreatingTicket = () => {
    return {
        type: DELETE_CREATING_TICKET,
    }
};

export const clearBalls = () => {
    return {
        type: CLEAR_BALLS,
    }
};

export const getRandomSelectedMaxValue = payload => {
    return {
        type: GET_RANDOM_SELECTED_MAX_VALUE,
        payload
    }
}