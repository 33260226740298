import React from "react";
//import LeftTopBox from './leftTopBox/index';
import LeftMenuWrapper from './leftMenuBox/index';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import isMobile from "../../helpers/mobile";
import FinishedTickets from "../rightColumn/rightMenuBox/rightMenuWeb/finishedTickets";
import {getFinishedTickets} from "../../actions/ticketActions";

const LeftColumn = (props) => {
    return(
                <div className={`${props.isClose ? "l-column-wrapper l-column-mini":"l-column-wrapper"}`}>
                    {!(isMobile() || props.mobileSize) /*&& <LeftTopBox />*/}
                    <LeftMenuWrapper />
                    {!(isMobile() || props.mobileSize) && <FinishedTickets finishedTickets={props.ticketFinishedHistory}
                                                                           totalCount={props.totalCount} getFinishedTickets={props.getFinishedTickets}/>}
                </div>
        )
    }

export default connect(state => ({
        ticketFinishedHistory: state.tickets.ticketFinishedHistory.tickets ? state.tickets.ticketFinishedHistory.tickets : null,
        totalCount: state.tickets.ticketFinishedHistory.totalCount,
        isClose: state.regions.isClose,
        mobileSize: state.mobile.mobileSize
    }),
    disptach => bindActionCreators({getFinishedTickets}, disptach))(LeftColumn);

