import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";

import TicketWrapper from '../ticketWrapper/index';
import {
    getAllWaitingTickets,
    deleteTicket,
    getFinishedTickets,
    getUpDateTickets,
} from "../../../../actions/ticketActions";
import {withTranslation} from 'react-i18next';
import CreateTickets from "./createTickets";
import WinnerTickets from "./winnerTickets";


const RightMenuWeb = (props) => {
    useEffect(() => {
        if (props.token) {
            props.getUpDateTickets(props.token, props.partnerId, props.gameId)
            props.getAllWaitingTickets(props.token);
            props.getFinishedTickets(0);
        }

    }, [props.token])


    let checkTicketData = () => {
        if (props.competitionId === null && props.createdTickets.length === 0 && props.winnerTickets.length === 0 || props.token === null) {
            return true
        }
        return false
    }

    // const { t } = this.props;
    return (
        <div className="r-full-menu-wrapper-box">
            {checkTicketData() ?
                <div className="fav-numbers-info-box">
                    <p>
                        choose your favorite
                        <br/>
                        numbers from the left side
                    </p>
                </div>
                :
                <div className="tickets-container">
                    {props.competitionId !== null && <TicketWrapper balls={props.selectedBalls} open={true}/>}
                    <CreateTickets createdTickets={props.createdTickets}/>
                    <WinnerTickets winnerTickets={props.winnerTickets}/>
                </div>
            }

        </div>
    );

}

export default compose(
    withTranslation("translation"),
    connect(
        (state) => ({
            winnerTickets: state.tickets.winnerTickets ? state.tickets.winnerTickets.tickets : null,
            competitionId: state.tickets.currentCreatingTicket.competitionId,
            createdTickets: state.tickets.createdTickets.tickets ? state.tickets.createdTickets.tickets : null,
            selectedBalls: state.tickets.currentCreatingTicket.selectedBalls,
            totalCount: state.tickets.createdTickets.totalCount,
            token: state.user.token,
            theSameBalls: state.droppedNumber.theSameBalls ? state.droppedNumber.theSameBalls : null,
            roundId: state.rounds.statusRound.roundId,
            gameEnd: state.tickets.gameEnd,
            round: state.rounds.round,
            partnerId: state.user.partnerId,
            gameId: state.user.gameId
        }),
        dispatch => bindActionCreators({
            getFinishedTickets,
            getAllWaitingTickets,
            deleteTicket,
            getUpDateTickets
        }, dispatch),
    ))(RightMenuWeb);


