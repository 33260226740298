import React, { Component } from 'react';
import Game from '../centerColumn/game/index';
import Balls from '../centerColumn/balls/index';
import Lobby from '../lobby/index';

class CenterColumn extends Component {
    render() {
        return(
            <div className="c-column-wrapper">
                <div className="game-lobby-wrapper">
                    <Game />
                    <Balls />
                </div>
                <Lobby />
            </div>
        )
    }n
}

export default CenterColumn;