import {DELETE_SEARCH_RESULTS, FILTER_BY_SELECT, GET_REGIONS, GET_SEARCH_RESULTS, IS_CLOSE} from "../constants";

const ACTION_HANDLERS = {
    [GET_REGIONS]: (state, action) => {
        return {
            ...state,
            regionsList: action.payload
        }
    },
    [GET_SEARCH_RESULTS]: (state, action) => {
        return {
            ...state,
            searchResults: action.payload
        }
    },
    [DELETE_SEARCH_RESULTS]: (state, action) => {
        return {
            ...state,
            searchResults: action.payload
        }
    },
    [FILTER_BY_SELECT ]: (state, action) => {
        return {
            ...state,
            filterBySelect: action.payload
        }
    },
    [IS_CLOSE]: (state, action) => {
        return {
            ...state,
            isClose: action.payload
        }
    }
}

const initialState = {
    regionsList: null,
    searchResults: null,
    filterBySelect: "All",
    isClose: false
}

const regionsReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default regionsReducer;