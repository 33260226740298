import UI from './UiReducer';
import regionReducer from './regionReducer';
import competitionReducer from './competitionReducer';
import roundReducer from './roundReducer';
import favoriteReducer from './favoriteReducer';
import quickLotteriesReducer from './quickLotteriesReducer';
import ticketReducer from "./ticketReducer";
import userReducer from "./userReducer";
import numberReducer from "./numberReducer";
import mobileReducer from "./mobileReducer";

let reducers = {
    UI,
    regions: regionReducer,
    competitions: competitionReducer,
    rounds: roundReducer,
    favorites: favoriteReducer,
    quickLotteries: quickLotteriesReducer,
    tickets: ticketReducer,
    user: userReducer,
    droppedNumber: numberReducer,
    mobile: mobileReducer
}

export default reducers;