import {GET_COEFFICENTS, GET_QUICK_LOTTERIES} from '../constants';

const ACTION_HANDLERS = {
    [GET_QUICK_LOTTERIES]: (state, action) => {
        return {
            ...state,
            quickLotteries: action.payload,
        }
    },
    [GET_COEFFICENTS]: (state, action) => {
          return {
              ...state,
              coifficent: action.payload
          }
    }
}

const initialState = {
    quickLotteries: null,
    coifficent: []
}
const quickLotteriesReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default quickLotteriesReducer;