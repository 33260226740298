import React, {Component} from 'react';
import PlacedBetsCount from './PlacedBetsCount';
import Flag from '../../../../helpers/flag';
import Country from '../../../../helpers/country';
import {connect} from 'react-redux';
import countrySelector from '../../../../selectors/countrySelector';
import {withTranslation} from 'react-i18next';

import {compose} from "redux";

class PlacedBetsInfo extends Component {

    render() {
        const { t } = this.props;
        return (
             <div className="placed-bets-info-wrapper">
                <div className="placed-bets-info">

                    <h1>Please place your bets</h1> 
                    {/* { t("please-bets") } */}

                    {/* <h1>drawing now</h1> */}
                    <div className="round-id-wrapper">
                        <Flag countryCode={this.props.countryCode}/>
                        <div className="round-id">
                            <span>Round:</span>
                            <span>ID:</span>
                        </div>
                        <div>
                            <Country
                                name={this.props.name}
                                selectedCount={this.props.selectedCount}
                                numbersCount={this.props.numbersCount}
                            />
                            <span className="roundNumber">{this.props.roundNumber}</span>
                        </div>
                    </div>
                </div>
                <PlacedBetsCount selectedCount={this.props.selectedCount}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        roundNumber: state.rounds.round && state.rounds.roundsList.length !== 0 ? `#${state.rounds.round.RoundNumber}` : 'NA',
        name: state.rounds.competition ? state.rounds.competition.Name : null,
        selectedCount: state.rounds.competition ? state.rounds.competition.MaxSelectedNumbersCount : 0,
        numbersCount: state.rounds.competition ? state.rounds.competition.NumbersCount : 0,
        countryCode: countrySelector(state),
    }
}


export default compose(
    withTranslation("translation"),
    connect(mapStateToProps,
        null),
)(PlacedBetsInfo);


