import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withTranslation} from 'react-i18next';
import RightMenuWeb from './rightMenuWeb/index';
import RightMenuMobile from './rightMenuMobile/index';
import isMobile from "../../../helpers/mobile";


class RightMenuWrapper extends Component {

    render() {
        return (
            <Fragment>
            {! (isMobile()  || this.props.mobileSize) ?
                <RightMenuWeb />
                :
                <RightMenuMobile/>
            }
            </Fragment>
        );
    }
}

export default compose (
    withTranslation("translation"),
    connect(
    state => ({
        mobileSize: state.mobile.mobileSize
    }),
    dispatch => bindActionCreators({
    }, dispatch),
))(RightMenuWrapper);


