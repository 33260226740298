import {
    GET_FIVE_DROPPED_NUMBER, GET_HOT_NUMBER,
    GET_DROPPED_NUMBER, ANIMATION_END,
    THE_SAME_NUMBER, CLEAR_DROPPED_NUMBER, CLEAR_SAME_GAME,
} from "../constants";
import {call} from "./call";
import {openMessagePopup} from "./UI_actions";

export const getHotNumber = (id) => (dispatch) => {

    const data = {
        Controller: "DroppedNumber",
        Method: "GetMostDroppeds",
        RequestData: {CompetitionId: id}
    };

    call(data)()
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_HOT_NUMBER,
                    hotNumber: res.data.Object
                })
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

export const getFiveDroppedNumber = (item) => (dispatch, getState) => {
    let id = item.CompetitionId ? item.CompetitionId : item.Id
    const data = {
        Controller: "DroppedNumber",
        Method: "GetTopMostDroppeds",
        RequestData: {CompetitionId: id, Count: 5}
    }
    call(data)()
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_FIVE_DROPPED_NUMBER,
                    payload: res.data.Object
                })

            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
}

// start connect
let ws
let connect = (id, dispatch) => {
    ws = new WebSocket(`wss://nlotto-api-stage.betcoapps.com/en/1/api/WebSocket/DroppedNumbersNotify`)
    ws.onopen = () => {
        ws.send(id);
    }

    ws.onmessage = (event) => {
        let res = JSON.parse(event.data);
        if (res.Status === "ERROR" && res.Message === "Round Is Crashed") {
            dispatch(openMessagePopup('something go wrong, your tickets will roll backed'))
        }
        if (res.Status === "OK") {
            // console.log(res.Data.DroppedNumbers, "dropped numbers")            
            dispatch({
                type: GET_DROPPED_NUMBER,
                payload: {
                    droppedNumbers: res.Data.DroppedNumbers,
                    roundId: res.RoundId
                }
            })
        }
    }

    ws.onclose = (e) => {
        console.log("websocket close")
        setTimeout(() => {
            connect(id, dispatch);
        }, 5000)
    }

    ws.onerror = (err) => {
        dispatch(openMessagePopup('Connection Error'))
        ws.close();
    }
}
//end connect

export const getDroppedNumber = (item) => (dispatch, getState) => {
    let id = item.CompetitionId ? item.CompetitionId : item.Id
    if (!ws) {
        connect(id, dispatch)
    } else {
        if (ws.readyState) {
            ws.send(id)
        }

    }
}

export const setSameBalls = (number) => ({type: THE_SAME_NUMBER, payload: number})
export const setAnimationEnd = (bool) => ({type: ANIMATION_END, payload: bool})
export const clearDroppedNumbers = () => ({type: CLEAR_DROPPED_NUMBER})
export const clearTheSameGame = () => ({type: CLEAR_SAME_GAME})





