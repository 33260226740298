import React from 'react';

const Loading = (props) => {
    return(
        <div className="loading-wrapper">
            <div className="progress-circular" 
                 style={{width: `${props.width}px`, height: `${props.height}px`}}>
            </div>
        </div>
    )
}

export default Loading;