import {OPEN_POPUP, CLOSE_POPUP, OPEN_MOBILE_COMPONENT} from './../constants';

const ACTION_HANDLERS = {
    [OPEN_POPUP] : (state, action) => {
        return {
            ...state,
            popup:  action.data
        }
    },
    [CLOSE_POPUP] : (state) => {
        return {
            ...state,
            popup: null
        }
    },
    [OPEN_MOBILE_COMPONENT] : (state, action) => {
        return {
            ...state,
            component:  action.data
        }
    },
}

const initialState = {
    popup: null,
    component: 'home'
}

const UiReducer = (state= initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;

}

export default UiReducer;