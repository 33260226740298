import React, { useEffect, useRef, useCallback, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeAnimation from "./timeAnimation/index";
import PlacedBetsInfo from "./placedBetsInfo/index";
import BallAnimationWrapper from "./ballAnimation/index";
import StartTime from "../../../helpers/startTime";
import { deleteRound, getRounds } from "../../../actions/roundActions";
import {clearDroppedNumbers, clearTheSameGame, setAnimationEnd, setSameBalls} from "../../../actions/numberActions";
import {addFinishedTicket, clearUpdateTickets, setGameEnd} from "../../../actions/ticketActions";

import { useTranslation } from "react-i18next";

const Game = props => {
 
   const { t } = useTranslation();
   /**
    * Declare a new state variable, which we'll call "animationStarted"
    * @param animationStarted = boolean
    */
   const [animationStarted, setAnimation] = useState(false);

   const ballAnimation = useRef(null);
   const animationText = useRef(null);
   const ballItemsContainer = useRef(null);

   /**
    * Declare a new state variable, which we'll call "animationStarted"
    * @param droppedNumbers = array
    */


   let count = 0;
   let ballItemWrapper = document.getElementsByClassName("game-ball-wrapper")[
      count
   ];

   useEffect(() => {
      if (props.droppedNumbers.length && props.animationEnd) {
         dropBall();
      } else if (animationStarted) {
         dropBall();
      }
   }, [props.animationEnd, animationStarted]);

   useEffect(() => {
      if (
         props.droppedNumbers.length > 0 &&
         props.animationEnd === true &&
         !props.droppedStartTime
      ) {
         props.setAnimationEnd(false);
      } else if (props.droppedStartTime && props.droppedNumbers.length) {
         start();
         setAnimation(true);
      }
   }, [props.droppedNumbers, props.droppedStartTime]);

   useEffect(() => {
      if (
         ballItemsContainer &&
         ballItemsContainer.current &&
         ballItemsContainer.current.children
      ) {
         //console.log('ballItemsContainer.current.children', ballItemsContainer.current.children);
      }
   }, [ballAnimation, ballItemsContainer]);

   const renderBalls = number => {
      if (ballItemWrapper !== null) {
         if (ballItemsContainer && ballItemsContainer.current) {
            ballItemWrapper = ballItemsContainer.current.children[count];
            let elem = document.createElement("div");
            elem.className = "ball-item-dropped";
            let span = document.createElement("span");
            span.innerHTML = number;
            elem.appendChild(span);
            ballItemWrapper.appendChild(elem);
            count++;
         }
      }
   };

   let start = () => {
      let oldTime = new Date(props.droppedStartTime);
      let newTime = new Date();
      let seconds = newTime.getSeconds() - oldTime.getSeconds() - 5;
      for (let i = 0; i < seconds; i++) {
         renderBalls(props.droppedNumbers[i]);
      }
      for (let i = 0; i < seconds; i++) {
         props.droppedNumbers.shift();
      }
   };

   const dropBall = useCallback(() => {
      if (
         ballAnimation.current !== null &&
         animationText !== null &&
         ballItemWrapper !== null &&
         props.droppedNumbers !== null
      ) {
         // console.log(ballAnimation.current,'current', ballAnimation, 'ballAnimation')
         ballAnimation.current.className = "ball-animation";
         animationText.current.textContent = props.droppedNumbers[0];
         ballAnimation.current.onanimationend = () => {
            props.createdTickets.map((el, index) => {
               el.Balls.map(number => {
                  if (number === props.droppedNumbers[0]) {
                     props.setSameBalls(number);
                  }
               });
            });
            renderBalls(props.droppedNumbers[0]);
            ballAnimation.current.classList.remove("ball-animation");
            animationText.current.textContent = "";
            props.droppedNumbers.shift();
         };

         if (props.droppedNumbers.length - 1 > 0) {
            setTimeout(() => dropBall(), 1000);
         } else {
            setTimeout(() => {
               let elem = document.getElementsByClassName("ball-item-dropped");
               for (let i = 0; i < elem.length; i++) {
                  elem[i].style.display = "none";
                  props.setGameEnd(true);
               }

               let expectedCount = 1;
               let item = props.competition;
               let skippingCount
               props.getRounds(item, skippingCount ,expectedCount);
               props.deleteRound();
               props.addFinishedTicket();
               props.clearTheSameGame()
            }, 6000);
         }
      }
   }, [
      ballItemWrapper,
      ballAnimation.current,
      animationText,
      ballItemsContainer,
      props.droppedNumbers
   ]);

   const renderBallsWrapper = () => {
      return new Array(20).fill(null).map((e, i) => {
         return (
            <div
               className={`game-ball-wrapper ball-item-wrapper avoid-clicks`}
               key={i}
            />
         );
      }, []);
   };

   return (
      <div className="game-container">
         <div>
            <div className="info">
               <span>
                  {t("numbers-rounds")}
                  {/*Numbers ordered by the frequency of dropouts over the last
                     100 rounds*/}
               </span>
            </div>
            <div
               //  onClick={() => setAnimation(!animationStarted)}
               className="round-start-time"
            >
               <span>Start:</span>
               <StartTime startTime={props.startTime} />
            </div>
         </div>
         <div className="game-wrapper">
            <div className="placed-bets-container">
               {props.animationEnd ? (
                  <div
                     className="ball-items-container"
                     ref={ballItemsContainer}
                  >
                     {renderBallsWrapper()}
                  </div>
               ) : (
                  <TimeAnimation />
               )}
            </div>
            <BallAnimationWrapper
               ref={{
                  ballAnimation,
                  animationText
               }}
               animationStarted={props.animationEnd}
               startTime={props.startTime}
            />
         </div>
         
       {/*  {!(isMobile() || props.mobileSize) ? null : <FinishedTickets />}*/}

         <PlacedBetsInfo maxSelectedCount={props.maxSelectedCount} />
      </div>
   );
};

export default connect(
   state => ({
      startTime:
         state.rounds.round && state.rounds.roundsList.length !== 0
            ? state.rounds.round.StartTime
            : null,
      droppedNumbers: state.droppedNumber.droppedNumbers.number
         ? state.droppedNumber.droppedNumbers.number
         : null,
      droppedStartTime: state.droppedNumber.droppedNumbers.droppedStartTime
         ? state.droppedNumber.droppedNumbers.droppedStartTime
         : null,
      animationEnd: state.droppedNumber.animationEnd,
      createdTickets: state.tickets.createdTickets.tickets
         ? state.tickets.createdTickets.tickets
         : null,
      endGame: state.tickets.endGame,
      competition: state.rounds.competition,
      mobileSize: state.mobile.mobileSize
   }),
   dispatch =>
      bindActionCreators(
         {
            deleteRound, setAnimationEnd, setSameBalls,
            setGameEnd,getRounds, addFinishedTicket,
            clearUpdateTickets, clearDroppedNumbers, clearTheSameGame
         },
         dispatch
      )
)(Game);
