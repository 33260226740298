const  getUrlFromIframe = () => {

    let url = window.location.href

    let param=  url.split('?');
    /**
     * BUG FIXING
     * @param[1] is undefined
     * @param[0] should be instead
     * this comment should be created as a bug on board and deleted 
     */
    let params = param[0].split("exit")
    let withoutHttpUrl = params[0].toString();
    let withoutAnd= withoutHttpUrl.split('&');
    let modeAndReal_play= withoutAnd[0];
    let tokenAndValue = withoutAnd[1];
    let gameId = withoutAnd[2];
    let userID = withoutAnd[3];
    let currency = withoutAnd[4];
    let language = withoutAnd[5];
    let partnerId = withoutAnd[6];
    let keyValueMode = modeAndReal_play.toString().split('=');
    let keyMode = keyValueMode[0];
    let  valueMode = keyValueMode[1];

    localStorage.setItem(keyMode, valueMode)

    if(valueMode === "real_play" ) {
        let keyValueToken = tokenAndValue.toString().split('=')
        let tokenKey = keyValueToken[0]
        let tokenValue = keyValueToken[1]
        localStorage.setItem(tokenKey, tokenValue)

        let keyValueGameId = gameId.toString().split('=')
        let GameIdKey = keyValueGameId[0]
        let GameIdValue = keyValueGameId[1]
        localStorage.setItem(GameIdKey, GameIdValue)

        let keyValueUserID = userID.toString().split('=')
        let UserIDKey = keyValueUserID[0]
        let UserIDValue = keyValueUserID[1]
        localStorage.setItem(UserIDKey, UserIDValue)

        let keyValueCurrency = currency.toString().split('=')
        let currencyKey = keyValueCurrency[0]
        let currencyValue = keyValueCurrency[1]
        localStorage.setItem(currencyKey, currencyValue)

        let keyValueLanguage= language.toString().split('=')
        let languageKey = keyValueLanguage[0]
        let languageValue = keyValueLanguage[1]
        localStorage.setItem(languageKey, languageValue)

        let keyValuePartnerId= partnerId.toString().split('=')
        let partnerIdKey = keyValuePartnerId[0]
        let partnerIdValue = keyValuePartnerId[1]
        localStorage.setItem(partnerIdKey, partnerIdValue)
    } else if(valueMode === "fun") {
        //console.log(valueMode,'valueMode')
    }
}


export default  getUrlFromIframe;