import {
    GET_REGIONS,
    GET_SEARCH_RESULTS,
    DELETE_SEARCH_RESULTS,
    FILTER_BY_SELECT,
    IS_CLOSE
} from "../constants";
import {openMessagePopup} from './UI_actions';
import {call} from "./call";

/**
 * getting all regions from backend
 * @param dispatch function
 */
export const getRegions = () => dispatch => {
    call({
        Controller: "Region",
        Method: "GetAll"
        /*  TimeZone: clientTimezone*/
    })()
        .then(res => {
            //handle success
            if (res.status === 200) {
                dispatch({
                    type: GET_REGIONS,
                    payload: res.data.Object,
                });
            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => {
            dispatch(openMessagePopup(err.message))
        });
};

export const searchRegionCompetitions = (text) => dispatch => {
    const data = {
        Controller: "Region",
        Method: "SearchRegionsAndCompetitions",
        RequestData: {"Text": text}
    }
    call(data)()
        .then(res => {
            //handle success
            if (res.status === 200) {
                //let arr = []
               /* const newCompetitions = res.data.Object.map(item => {
                    arr.push(item)
                    /!*return {[item.Id]: item.Competitions}*!/
                })*/
                /*dispatch({
                    type: GET_COMPETITIONS_ARRAY,
                    competitions: newCompetitions,
                })*/
                dispatch({
                    type: GET_SEARCH_RESULTS,
                    payload: res.data.Object
                })

            } else dispatch(openMessagePopup(res.message))
        })
        .catch(err => dispatch(openMessagePopup(err.message)))
};

export const deleteCompetitions = (text) => dispatch => {
    dispatch({
        type: DELETE_SEARCH_RESULTS,
        payload: []
    })
};

export const filterBySelect = (data) => dispatch => {
    dispatch({
        type: FILTER_BY_SELECT,
        payload: data
    })
};
export const isClosed = (data) => dispatch => {
    dispatch({
        type: IS_CLOSE,
        payload: data
    })
};
