import React, { Fragment } from 'react'
import Ticket from "../ticketWrapper/ticket"
// import {useTranslation} from "react-i18next";

const WinnerTickets = (props) => {
    return (
        <Fragment>
            {!props.winnerTickets.length > 0 ? null :
                <div id="finished-tickets" className="finished-tickets winning-tickets created-tickets-wrapper ">
                    <div className='ticket-text'>Winning Tickets 
                        {/* <span> Total - {props.winnerTickets.length}</span> */}
                    </div>
                    {props.winnerTickets.map((item, index) => {
                        return <Ticket deleteIcon={false} key={index} id={item.Id} name={item.Name}
                                       totalWin={item.WinAmount} totalBet={item.BetAmount}
                                       balls={item.Balls} isState={item.State}
                                       matchedBalls={item.MatchedBalls} ticketNumber={item.TicketNumber}
                                       loose={item.WinAmount === 0 ? 0 : ""} systemType={item.SystemType}/>
                    })}
                </div>}
        </Fragment>
    )
}

export default WinnerTickets