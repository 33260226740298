import {
    GET_DROPPED_NUMBER, GET_FIVE_DROPPED_NUMBER,
    GET_HOT_NUMBER, ANIMATION_END, CLEAR_SAME_GAME,
    THE_SAME_NUMBER, CLEAR_DROPPED_NUMBER
} from "../constants";

const ACTION_HANDLERS = {

    [GET_HOT_NUMBER]: (state, action) => {
        return {
            ...state,
            hotNumber: action.hotNumber
        }
    },
    [GET_FIVE_DROPPED_NUMBER]: (state, action) => {
        return {
            ...state,
            fiveDroppedNumber: action.payload
        }
    },
    [GET_DROPPED_NUMBER]: (state, action) => {
        state.droppedNumbers.number = []
        state.droppedNumbers.droppedStartTime = null

        return {
            ...state,
            droppedNumbers: {
                number: action.payload.droppedNumbers,
                roundId: action.payload.roundId ? action.payload.roundId : null,
                droppedStartTime: action.payload.droppedStartTime ? action.payload.droppedStartTime : null
            }
        }
    },
    [ANIMATION_END]: (state, action) => {
        return {
            ...state,
            animationEnd: action.payload
        }
    },
    [THE_SAME_NUMBER]: (state, action) => {
        //state.theSameBalls = []
        return {
            ...state,
            theSameBalls: !state.theSameBalls ? [action.payload] : state.theSameBalls.concat([action.payload])
        }
    },
    [CLEAR_DROPPED_NUMBER]: (state) => {
        return {
            ...state,
            droppedNumbers: {
                number: [],
            }
        }
    },
    [CLEAR_SAME_GAME]: (state) => {
        return {
            ...state,
            theSameBalls: []
        }
    }
};

const initialState = {
    hotNumber: [],
    fiveDroppedNumber: [],
    droppedNumbers: {
        number: [],
        roundId: null,
        droppedStartTime: null
    },
    animationEnd: true,
    theSameBalls: []
}

const numberReducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default numberReducer;