let rus = {
    translation: {
        "please-bets": "пожалуйста, сделайте свои ставки",
            "numbers-rounds": "\n" +
        "Числа, упорядоченные по частоте отсева за последние 100 раундов.",
        "tickets": "билеты",
        "result": "результат",
        "waiting-tickets": "Билеты ожидания",
        "finished-tickets": "Готовые билеты",
        "random-select": "случайный выбор",
        "clear": "Очистить",
        "hot-numbers": "Горячие числа в лотто"
    }
};

export default rus;