import React, {Component} from 'react';
import { connect } from "react-redux";
class BallItemWrapper extends Component{
    render(){
        return(
            <div className={`ball-item-wrapper ${this.props.roundsList.length !== 0 ? "" : "avoid-clicks"}`}>
                {this.props.children}
            </div>
        )
    }
}
export default connect(
    state => ({
        roundsList: state.rounds.roundsList
    }),
   null,
)(BallItemWrapper)