import React, {Component} from 'react';
import HotNumber from './HotNumber';
import Loading from "../../../loading";

class HotNumberItem extends Component {
    render() {
        return (
           <>
               {this.props.data && this.props.data.length > 0 ? this.props.data.map((itam, index) => {
                   return  <div className="hotNumbers-items" key={index}>
                                <HotNumber number={itam.Number}/>
                                <div className="progress-container">
                                    <div className="progress-bar-wrapper">
                                        <div className="progress-bar" style={{width: `${itam.Percentage }%`}}></div>
                                    </div>
                                    <span>{itam.Percentage}/100</span>
                                    <span>{itam.Percentage}%</span>
                                </div>
                        </div>
                }) : <Loading width="40" height="40"></Loading> }
                </>
        )
    }
}

export default HotNumberItem;