import React, {Component, Fragment} from "react";
import RightColumn from "./components/rightColumn/index";
import resize from "./helpers/resize";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getRegions} from "./actions/regionActions";
import Popups from "./popUps";
import {openMessagePopup, setGameId, setLanguages, setPartnerId} from "./actions/UI_actions";
import Loading from "./components/loading";
import {setToken} from "./actions/UI_actions";

import i18n from "i18next";

import Home from "./components/home"
import {checkMobile} from "./actions/mobileActions";
import isMobile from "./helpers/mobile";
import getUrlFromIframe from "./helpers/getUrl";
import {getAllCopmetitions} from "./actions/competitionActions";

/**
 * class App renders application
 */


class App extends Component {
    state = {
        ready: true
    };

    getDemoData = () => {
        this.props.setToken(localStorage.getItem("Token"));
        this.props.setPartnerId(localStorage.getItem("PartnerId"));
        this.props.setGameId(localStorage.getItem("GameID"));
    };

    updateDimensions = () => {
        this.props.checkMobile(window.innerWidth);
    };


    componentDidMount() {
        getUrlFromIframe()
        if (localStorage.getItem("mode") === "real_play") {
            this.getDemoData();
        }
        resize();
        window.addEventListener("resize", this.updateDimensions);
        i18n.changeLanguage(localStorage.getItem("Language"));
        this.props.setLanguages(localStorage.getItem("Language"));
        this.props.getAllCopmetitions()
        this.props.getRegions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        localStorage.clear()
    }

    render() {
        return (
            <Fragment>
                {this.state.ready ? (
                    <div className="app">
                        {!(isMobile() || this.props.mobileSize) ? <Home/> : null}
                        <RightColumn/>
                        <Popups/>
                    </div>
                ) : (
                    <Loading/>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        competitionId: state.rounds.competitionId,
        mobileSize: state.mobile.mobileSize,
        component: state.UI.component,
        creatingTicket: state.tickets.currentCreatingTicket.competitionId,
        token: state.user.token
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRegions, openMessagePopup,
            setToken, setLanguages,
            checkMobile, setPartnerId, setGameId, getAllCopmetitions
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
